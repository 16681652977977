.homeSlider {margin-bottom: 0 !important;float: left;width: 100%;overflow: hidden;
    &__Slide {height: 100vh !important;float: left;width: 100%;position: relative;background-size: cover;background-repeat: no-repeat;width: 100%;z-index: 1;
        &:before {content: '';z-index: -1;position: absolute;top: 0;right: 0;bottom: 0;left: 0;background: $black;opacity: 0.5;}
        .center {height: 100vh;}
    }
    h2 {font-size: 72px;color: $white;position: relative;z-index: 1;font-weight: bold;margin-bottom: 0;}
    h4 {font-size: 30px;font-weight: 300;color: $white;font-weight: normal;margin: auto;margin-top: 0;margin-bottom: 20px;}
    img {max-width: 200px;margin-bottom: 40px;}
    &__More {padding:20px 30px;text-transform: initial;color: $white;vertical-align: middle;border: 1px solid $white;border-top: 0;border-bottom: 0;position: relative;z-index: 1;line-height: 70px;outline:none;height: 70px;text-decoration: none;font-family: $second;text-transform: uppercase;font-weight: lighter;
          &:before {content: '';position: absolute;top: 0;left: 0;right: 0;height: 1px;background: $white;z-index: -1;transition:300ms;}
          &:after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background: $white;z-index: -1;transition:300ms;}
          &:hover {
            &:before {right: 30%;}
            &:after {left: 30%;}
          }
    }
    &__Inner {position: absolute;max-width: 800px;margin: auto;}
.topLeft {top: 150px;left: 50px;text-align: left;}
.topCenter {top: 150px;left: 0;right: 0;text-align: center;}
.topRight {top: 150px;right: 50px;text-align: right;}
.centerLeft {top: 50%;transform:translateY(-50%);left: 50px;text-align: left;}
.centerCenter {top: 50%;transform:translateY(-50%);left: 0;right: 0;text-align: center;}
.centerRight{top: 50%;transform:translateY(-50%);right: 50px;text-align: right;}
.bottomLeft {bottom:150px;left: 50px;text-align: left;}
.bottomCenter {bottom:150px;left: 0;right: 0;text-align: center;}
.bottomRight {bottom:150px;right: 50px;text-align: right;}
    &__Show {margin-top: 200px;color: $white;float: left;width: 100%;
            i {transform:rotate(90deg);}
    }


.arrowPrev, .arrowNext {display: block;position: absolute;top: 0;bottom: 0;width: 60px;height: 100%;z-index: 10000;}
.arrowPrev {height: 100vh;line-height: 100vh; opacity:1; transition:200ms;color: $white;font-size: 40px;padding-left: 15px;
  &:after {width: 70px;height: 100px;bottom: 45%;top: 45%;left: 0px;position: absolute;content: '';background: alpha($white,0.3);text-align: center;transition:200ms;}
  &:hover {transition:200ms;
    &:before {opacity: 0.6;transition:200ms;}
  }
  &:before {line-height: 100vh;opacity: 1;font-size: 60px !important;z-index: 10000;color: $white;position: absolute;left: 20px;transition:200ms;}
}

.arrowNext {height: 100vh;line-height: 100vh;transition:200ms;color: $white;font-size: 40px; opacity:1; transition:200ms;right: 0;text-align: right;padding-right: 15px;
  &:after {width: 70px;height: 100px;bottom: 45%;top: 45%;right: 0px;position: absolute;content: '';background: alpha($white,0.3);text-align: center;transition:200ms;}
  &:hover {transition:200ms;
    &:before {opacity: 0.6;transition:200ms;}
  }
  &:before {line-height: 100vh;opacity: 1;font-size: 60px !important;z-index: 10000;color: $white;position: absolute;right: 20px;transition:200ms;}
}
}
.about {float: left;width: 100%;padding: 80px 0;
  background-size: 400px auto;background-repeat: no-repeat;background-position: 800px bottom;background-attachment:fixed;
    h2 {
      
      color: $brand;
      margin: 0px;
      position: relative;
      z-index: 1;
      font-weight: bold;
      font-family: $second;
      text-transform: uppercase;

padding-top: 0px;
padding-bottom: 20px;
float: left;
font-size: 70px;
line-height: 37px;
&:before {
  position: absolute;left: -4px;bottom: 0;content: '';z-index: -1;border-bottom: 10px solid #ffcdcd;right: 0;
}
    }
    &__Cnt {float: left;width: 100%;}
    h3 {font-size: 31px;}
    p {color: $brand2;font-weight: lighter;font-size: 23px;}
     &__More {padding:0px 30px;text-transform: initial;color: $brand2;vertical-align: middle;border: 1px solid $brand2;border-top: 0;border-bottom: 0;position: relative;z-index: 1;line-height: 70px;outline:none;height: 68px;text-decoration: none;font-family: $second;text-transform: uppercase;font-weight: lighter;display: inline-block;
          &:before {content: '';position: absolute;top: 0;left: 0;right: 0;height: 1px;background: $brand2;z-index: -1;transition:300ms;}
          &:after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background: $brand2;z-index: -1;transition:300ms;}
          &:hover {
            &:before {right: 30%;}
            &:after {left: 30%;}
          }
    }
    &__Left {float: left;width: 70%;}
    &__widgetsWrap {float: left;width: 100%;margin-top: 50px;}
    &__Widgets {float: left;width: 31%;margin-left: 1%;margin-right: 1%;
        h3 {font-size: 30px;margin: 0;color: $brand2;}
        p {color: $brand2;}
    }
}
.news {
    float: left;width: 100%;text-align: center;padding: 80px 0;
    &__Clear {float: left;width: 100%;margin-top: 60px;}
    &__More {padding:20px 30px;text-transform: initial;color: $brand3;vertical-align: middle;border: 1px solid $brand3;border-top: 0;border-bottom: 0;position: relative;z-index: 1;line-height: 70px;outline:none;height: 70px;text-decoration: none;font-family: $second;text-transform: uppercase;font-weight: lighter;
          &:before {content: '';position: absolute;top: 0;left: 0;right: 0;height: 1px;background: $brand3;z-index: -1;transition:300ms;}
          &:after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background: $brand3;z-index: -1;transition:300ms;}
          &:hover {
            &:before {right: 30%;}
            &:after {left: 30%;}
          }
    }
    h2 {
      
      color: $brand;
      margin: 0px;
      position: relative;
      z-index: 1;
      font-weight: bold;
      font-family: $second;
      text-transform: uppercase;

padding-top: 0px;
padding-bottom: 20px;
float: left;
font-size: 70px;
line-height: 37px;
&:before {
  position: absolute;left: -4px;bottom: 0;content: '';z-index: -1;border-bottom: 10px solid #ffcdcd;right: 0;
}
    }
    h3 {font-size: 31px;float: left;clear: both;}
    &__Cnt {float: left;width: 100%;}
    &__Box {@include span-columns(4);text-align: left;
        &:nth-of-type(3n) {margin-right: 0;}
        &--img {float: left;width: 100%;height: 200px;background-size: cover;background-repeat: no-repeat;background-position: center center;}
        &--cnt {float: left;width: 100%;}
        a {text-decoration: none;
            &:hover {
                p {color: $black;}
            }
        }
        h4 {float: left;width: 100%;color: $brand2;font-size: 24px;text-transform: uppercase;font-family: $second;margin-bottom: 0;}
        h6 {color: $brand2;margin: 0;}
        p {color: $brand2;font-size: 14px;}
        &--more {padding:10px 20px;text-transform: initial;color: $brand2;vertical-align: middle;border: 1px solid $brand2;border-top: 0;border-bottom: 0;position: relative;z-index: 1;line-height: 70px;outline:none;height: 70px;text-decoration: none;font-family: $second;font-weight: lighter;
          &:before {content: '';position: absolute;top: 0;left: 0;right: 0;height: 1px;background: $brand2;z-index: -1;transition:300ms;}
          &:after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background: $brand2;z-index: -1;transition:300ms;}
          &:hover {
            &:before {right: 30%;}
            &:after {left: 30%;}
          }
    }
    }
    
}

.getInTouch {float: left;width: 100%;padding: 80px 0;height: 400px;background-size: auto;background-repeat: no-repeat; background-attachment: fixed;
    h2 {font-size: 45px;color: $white;margin: 0px;position: relative;z-index: 1;font-weight: bold;}
    p {font-size: 21px;color: $white;}
    &__Left {float: left;width: 59%;margin-right: 1%;}
    &__Right {float: left;width: 39%;margin-left: 1%;position: relative;
        input {height: 50px;line-height: 50px;float: left;width: 80%;padding: 10px;margin-bottom: 10px;border: 1px solid $white;background-color: $white;color: $brand2;font-size: 18px;}
        textarea {max-width: 100%;min-width: 100%;float: left;width: 100%;padding: 10px;margin-bottom: 10px;height: 200px;border: 1px solid $brand2;background-color: transparent;}
        input[type="submit"] {background: transparent;border: 0;margin: 0;color: $white;line-height: 36px;padding: 0;width: 100%;}

        div.wpcf7-response-output {
    margin: 0 !important;
    float: left;
    position: absolute;
    top: 90px;
    left: 0;
    color: #fff;
}
  .contactButton {border: 1px solid $white;border-top: 0;border-bottom: 0;position: relative;z-index: 1;outline:none;height: 50px;float: left;width: 19%;margin-left: 1%;
          &:before {content: '';position: absolute;top: 0;left: 0;right: 0;height: 1px;background: $white;z-index: -1;transition:300ms;}
          &:after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background: $white;z-index: -1;transition:300ms;}
          &:hover {
            &:before {right: 30%;}
            &:after {left: 30%;}
          }
      }
    }
}
.clients {float: left;width: 100%;padding: 80px 0;
   h2 {
      
      color: $brand;
      margin: 0px;
      position: relative;
      z-index: 1;
      font-weight: bold;
      font-family: $second;
      text-transform: uppercase;

padding-top: 0px;
padding-bottom: 20px;
display: inline-block;
font-size: 30px;
line-height: 17px;
&:before {
  position: absolute;left: -4px;bottom: 0;content: '';z-index: -1;border-bottom: 5px solid #ffcdcd;right: 0;
}
&:hover {
  &:before {border-color: $brand;}
}
    }
    a {text-decoration: none;}
    &__Clear {float: left;width: 100%;margin-bottom: 30px;text-align: center;}
  ul {padding: 0;margin: 0;margin-bottom: 0 !important;float: left;width: 100%;
    li {list-style: none;display: inline-block;text-align: center;height: 100px;line-height: 100px;width: 16%;float: left;
      img {max-height: 60px;vertical-align: middle;max-width: 145px;opacity: 1;filter:grayscale(100%);}
      a {text-decoration: none;
        &:hover {
          img {opacity: 0.6;}
        }
      }
    }
  }
}
.advice {
  float: left;width: 100%;height: 400px;background-size: cover;background-repeat: no-repeat;z-index: 1;position: relative;background-position: center center;background-attachment:fixed;
    .center {height: 400px;}
    &:before {content: '';z-index: -1;position: absolute;top: 0;right: 0;bottom: 0;left: 0;background: $black;opacity: 0.5;}
    &__Inner {position: relative;top: 50%;transform:translateY(-50%);text-align: center;}
    h2 {font-size: 45px;color: $white;margin: 0px;position: relative;z-index: 1;font-weight: bold;margin-bottom: 20px;}
      &__More {padding:0px 30px;text-transform: initial;color: $white;vertical-align: middle;border: 1px solid $white;border-top: 0;border-bottom: 0;position: relative;z-index: 1;line-height: 70px;outline:none;height: 68px;text-decoration: none;font-family: $second;text-transform: uppercase;font-weight: lighter;display: inline-block;
          &:before {content: '';position: absolute;top: 0;left: 0;right: 0;height: 1px;background: $white;z-index: -1;transition:300ms;}
          &:after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background: $white;z-index: -1;transition:300ms;}
          &:hover {
            &:before {right: 30%;}
            &:after {left: 30%;}
          }
    }
    
}
.centerMe {text-align: center;}
.mobile {display: none !important;}
@media only screen and (device-width: 1180px) {
  /* For general iPad layouts */
.clients {
  ul {
    li {width: 40%;margin-left: 5%;margin-right: 5%;margin-bottom: 5%;
      img {max-width: 100px;}
    }
  }
}
}
@media screen and (max-width: 1280px) { 
  .nav {float: right;}
  .homeSlider {
  h2 {font-size: 34px;}
  h4 {font-size: 24px;}
  &__Slide {background-size: cover; background-position: center center !important; background-attachment: initial !important;}
}
.advice {background-attachment: initial;}
.getInTouch {
  background-attachment: initial;background-size: cover;
  }
// .clients {
//   ul {
//     li {width: 40%;margin-left: 5%;margin-right: 5%;margin-bottom: 5%;
//       img {max-width: 100px;}
//     }
//   }
// }
}
@media screen and (max-width: 800px) { 
.mobile {display: inline-block !important;}
.productForm__Call {float: left;text-align: center;}
.site {padding-top: 0;}
.siteHeader {height: auto;position: relative;margin-bottom: 0;top: 0 !important;transform:translateY(0) !important;
  &__Top {height: auto;line-height: 1;}
  &__Left {display: none;}
  &__Right {display: none;}
  &__Logo {width: 100%; padding-top: 0px;padding-bottom: 0px;text-align: right;padding-right: 15px;
    img {max-width: 130px;}
    .logoWhite {left: auto;right: 10px;top: 10px;}
    .logoBlack {left: auto;right: 10px;top: 10px;}
  }
}
.nav {position: absolute;background: $white;transform:translateX(-100%);z-index: 1000000000;transition:500ms !important;
  ul {height: auto;line-height: 1;display: block;float: left;width: 100%;}
  li {display: table;float: none;width: auto;
    ul {display: block;opacity: 1;visibility: visible;transform: translateY(0%);position: relative;top: auto;
      li {background-color: transparent;
        a {line-height: 2 !important;}
        &:first-of-type {padding-top: 0px;}
        &:last-of-type {padding-top: 0px;
          &:before {display: none;}
        }
      }
    }
  }
  a {float: none;width: auto;line-height: 2;color: $brand2;text-align: left;}
  li.current_page_item {
    a {color: $brand2 !important;}
  }
}
.callNav {position: absolute;right: -55px;top: 10px;padding: 10px 15px;background: $white;cursor: pointer;
  i {color: $brand2;transition:700ms;}
}
.activeNav {transform:translateX(0%);opacity: 1;transition:500ms !important;
  .callNav {right: 10px;background: $brand2;
    i {color: $white;transform:rotate(180deg);
      &:before {content: "\f00d" !important;}
    }
  }
}
.homeSlider {
  h2 {font-size: 34px;}
  h4 {font-size: 24px;}
  &__Slide {background-size: cover; background-position: center center !important; background-attachment: initial !important;}
}
.topLeft {top: 50% !important;transform:translateY(-50%) !important;left: 0 !important;right: 0 !important;text-align: center !important;}
.topCenter {top: 50% !important;transform:translateY(-50%) !important;left: 0 !important;right: 0 !important;text-align: center !important;}
.topRight {top: 50% !important;transform:translateY(-50%) !important;left: 0 !important;right: 0 !important;text-align: center !important;}
.centerLeft {top: 50% !important;transform:translateY(-50%) !important;left: 0 !important;right: 0 !important;text-align: center !important;}
.centerCenter {top: 50%;transform:translateY(-50%);left: 0;right: 0;text-align: center;}
.centerRight{top: 50% !important;transform:translateY(-50%) !important;left: 0 !important;right: 0 !important;text-align: center !important;}
.bottomLeft {top: 50% !important;transform:translateY(-50%) !important;left: 0 !important;right: 0 !important;text-align: center !important;}
.bottomCenter {top: 50% !important;transform:translateY(-50%) !important;left: 0 !important;right: 0 !important;text-align: center !important;}
.bottomRight {top: 50% !important;transform:translateY(-50%) !important;left: 0 !important;right: 0 !important;text-align: center !important;}
.homeImage {top: 0;height: 100vh;
    &:before {display: none;}
    &:after {display: none;}
}
.about {
  &__Left {width: 90%;margin-left: 5%;margin-right: 5%;}
  &__More {height: 48px;line-height: 50px;}
  h2 {font-size: 34px;}
  h3 {font-size: 24px;}
  p {font-size: 16px;}
}
.advice {background-attachment: initial !important;
  &__More {height: 48px;line-height: 50px;}
  &__Inner {width: 90%;margin-left: 5%;margin-right: 5%;}
  h2 {font-size: 34px;margin-bottom: 30px;}
}
.news {width: 90%;margin-left: 5%;margin-right: 5%;
  &__Cnt {width: 100%;margin-left: 0%;margin-right: 0%;}
  &__Box {width: 100%;margin-right: 0;margin-bottom: 25px;}
  &__More {height: 48px;line-height: 50px;padding: 13px 20px;}
  h2 {font-size: 34px;}
  h3 {font-size: 24px;}
  
}
.getInTouch {
  padding: 40px 0;background-attachment: initial;background-size: cover;
  .center {width: 90%;margin-left: 5%;margin-right: 5%;}
  &__Left {width: 100%;margin-left: 0%;margin-right: 0%;}
  &__Right {width: 100%;margin-left: 0%;margin-right: 0%;}
}
.clients {
  ul {
    li {width: 40%;margin-left: 5%;margin-right: 5%;margin-bottom: 5%;
      img {max-width: 100px;}
    }
  }
}
.siteFooter {
  &__Widget {width: 90%;margin-left: 5%;margin-right: 5%;margin-bottom: 5%;}
  &__Copy {text-align: center;
    span {margin-bottom: 15px;float: left;width: 100%;}
    ul {float: left;margin: auto;width: 100%;}
  }
}
.pageSingle {
  &__Inner {width: 90%;margin-left: 5%;margin-right: 5%;
    &--box {width: 96%;}
  }
}
.blog {
  width: 90%;margin-left: 5%;margin-right: 5%;
  &__Box {width: 100%;margin-right: 0;margin-left: 0;margin-bottom: 25px;}
}
.awwards {
  width: 90%;margin-left: 5%;margin-right: 5%;
  &__Box {width: 100%;margin-right: 0;margin-left: 0;margin-bottom: 25px;}
}
.sidebar {width: 90%;margin-left: 5%;margin-right: 5%;float: left;}
.postSingle {
  &__Top {padding-left: 5%;padding-right: 5%;}
  &__Inner {width: 90%;margin-left: 5%;margin-right: 5%;position: relative;
    ol {
      li {font-size: 14px;}
    }
  }
  &__Left {width: 90%;margin-left: 5%;margin-right: 5%;}
  &__Right {width: 90%;margin-left: 5%;margin-right: 5%;}
  &__InnerWithNo {width: 90%;margin-left: 5%;margin-right: 5%;}
}
.wpsl-store-below {
    width: 90% !important;
    margin: 0 5% !important;
}
.relatedPosts {
  &__Inner {width: 90%;margin-left: 5%;margin-right: 5%;}
}
.relatedProducts {
  &__Box {width: 80%;margin-left: 10%;margin-right: 10%;
      h2 {font-size: 14px;}
  }
}
.machineCategory {
  &__Box {width: 70%;margin-left: 15%;margin-right: 15%;}
}
.categoryProducts {
  &__Box {width: 70%;margin-left: 15%;margin-right: 15%;}
}
.productSingle {
  &__Inner {width: 90%;margin-left: 5%;margin-right: 5%;
    &--gallery {
      a {width: 48%;}
    }
  }
  &__Cover {background-size: 100% auto;margin-bottom: 0;width: 90%;margin-left: 5%;margin-right: 5%;}
}
.blogPage {
  &__Top {padding-top: 100px;}
  &__Content {padding: 0;width: 90%;margin-left: 5%;margin-right: 5%;}
  &__Box {width: 100%;
    &--cover {width: 100%;}
  }
}
.singleRepresentation {
  &__Box {width: 48%;margin-left: 1%;margin-right: 1%;margin-bottom: 2%;}
}
.page-template-page-club {
  .postSingle {
    &__Inner {
      li {margin-bottom: 10px;}
      span {font-size: 13px;line-height: 1.3 !important;float: left;width: 100%;margin-bottom: 20px;}
    }
  }
}
.productFormOutbox {margin-top: -700px;}
.productForm {width: 100%;padding: 10px;top: 0px;
    &__Close {top: 10px;right: 10px;}
}
.singleRepresentation {
  &__Hover {
    position: absolute;
    &--inner {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    top: 2.5%;
position: fixed;
p {font-size: 12px;}
a {font-size: 12px;}
}
}
}
}
@charset "UTF-8";
@import url(../../assets/bower_components/normalize.css/normalize.css);
/* Slider */
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700|Playfair+Display:400,700&subset=latin-ext");
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  /*
    img {
        display: block;
    }
    */
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px\9;
  /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }

.slick-prev:before, .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

body {
  font: 16px/1.5 "Open Sans", sans-serif;
  height: 100%;
  overflow-x: hidden; }

#__bs_notify__ {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -125px !important;
  width: 250px;
  height: 50px;
  border-radius: 0 !important;
  opacity: 0.5; }

.center {
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  .center::after {
    clear: both;
    content: "";
    display: table; }

.clearfix::after {
  clear: both;
  content: "";
  display: table; }

::selection {
  background: #cccccc;
  text-shadow: none; }

.t-Title, .t-Title--with-MarginBottom {
  text-transform: uppercase;
  font-size: 2.25em;
  line-height: 44px;
  letter-spacing: 3.5px;
  margin-top: 100px;
  font-weight: 300;
  margin-bottom: 0;
  text-align: center; }
  .t-Title--with-MarginBottom {
    margin-bottom: 70px; }

.t-input-Select {
  background: #e2e2e2;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  transition: 200ms;
  backface-visibility: hidden;
  margin-right: 10px; }
  .t-input-Select option {
    background: transparent; }
  .t-input-Select:hover {
    background: #d5d5d5; }
    .t-input-Select:hover:after {
      background-color: #d5d5d5; }
  .t-input-Select:active {
    transform: scale(0.97);
    transition: 60ms; }
  .t-input-Select:after {
    display: block;
    content: "";
    position: absolute;
    width: 40px;
    height: 100%;
    line-height: 43px;
    font-size: 0.75em;
    color: #999999;
    top: 0;
    right: 0;
    text-align: center;
    background: no-repeat center center;
    background-color: #e2e2e2;
    pointer-events: none;
    transition: 200ms; }
  .t-input-Select select {
    border: 0;
    -webkit-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    padding: 9px 10px 8px;
    outline: none;
    cursor: pointer;
    color: #4d4d4d;
    display: block;
    background: #FFF;
    background: transparent;
    padding-right: 40px;
    display: block; }
  .t-input-Select select[disabled] {
    color: rgba(0, 0, 0, 0.3); }

.t-MoreButton {
  border: 1px #FFF solid;
  display: inline-block;
  padding: 0 30px;
  line-height: 46px;
  text-decoration: none;
  font-size: 14px;
  color: #FFF;
  position: relative;
  overflow: hidden;
  z-index: 1;
  vertical-align: bottom;
  background: transparent;
  transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
  letter-spacing: 1px;
  font-weight: bold; }
  .t-MoreButton:hover {
    color: #ff0000; }
    .t-MoreButton:hover:before {
      opacity: 1;
      right: -100px;
      left: -100px; }
  .t-MoreButton:before {
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    top: -10px;
    bottom: -10px;
    transform: skew(40deg);
    background: #FFF;
    left: 100%;
    right: 110%;
    transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }

img {
  max-width: 100%;
  height: auto; }

a {
  cursor: pointer; }

img.alignright {
  float: right;
  margin: 0 0 1em 1em; }

img.alignleft {
  float: left;
  margin: 0 1em 1em 0; }

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.alignright {
  float: right; }

.alignleft {
  float: left; }

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.navigation li.active a {
  color: #35394b; }

.bluetable table {
  text-align: left;
  border: 1px solid #dbdbdb;
  -webkit-box-shadow: 1px 1px 3px 0 rgba(180, 180, 180, 0.2);
  -moz-box-shadow: 1px 1px 3px 0 rgba(180, 180, 180, 0.2);
  box-shadow: 1px 1px 3px 0 rgba(180, 180, 180, 0.2);
  margin-left: -80px;
  width: 760px; }

.bluetable th {
  padding: 10px 10px;
  background-image: linear-gradient(bottom, #f7f7f7 0, #fff 100%);
  background-image: -o-linear-gradient(bottom, #f7f7f7 0, #fff 100%);
  background-image: -moz-linear-gradient(bottom, #f7f7f7 0, #fff 100%);
  background-image: -webkit-linear-gradient(bottom, #f7f7f7 0, #fff 100%);
  background-image: -ms-linear-gradient(bottom, #f7f7f7 0, #fff 100%);
  background-color: #f9f9f9;
  border: 1px solid #e2e2e2; }

.bluetable td {
  padding: 10px 10px;
  vertical-align: middle;
  border: 1px solid #e2e2e2; }

.homeSlider {
  margin-bottom: 0 !important;
  float: left;
  width: 100%;
  overflow: hidden; }
  .homeSlider__Slide {
    height: 100vh !important;
    float: left;
    width: 100%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    z-index: 1; }
    .homeSlider__Slide:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #000;
      opacity: 0.5; }
    .homeSlider__Slide .center {
      height: 100vh; }
  .homeSlider h2 {
    font-size: 72px;
    color: #FFF;
    position: relative;
    z-index: 1;
    font-weight: bold;
    margin-bottom: 0; }
  .homeSlider h4 {
    font-size: 30px;
    font-weight: 300;
    color: #FFF;
    font-weight: normal;
    margin: auto;
    margin-top: 0;
    margin-bottom: 20px; }
  .homeSlider img {
    max-width: 200px;
    margin-bottom: 40px; }
  .homeSlider__More {
    padding: 20px 30px;
    text-transform: initial;
    color: #FFF;
    vertical-align: middle;
    border: 1px solid #FFF;
    border-top: 0;
    border-bottom: 0;
    position: relative;
    z-index: 1;
    line-height: 70px;
    outline: none;
    height: 70px;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: lighter; }
    .homeSlider__More:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #FFF;
      z-index: -1;
      transition: 300ms; }
    .homeSlider__More:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #FFF;
      z-index: -1;
      transition: 300ms; }
    .homeSlider__More:hover:before {
      right: 30%; }
    .homeSlider__More:hover:after {
      left: 30%; }
  .homeSlider__Inner {
    position: absolute;
    max-width: 800px;
    margin: auto; }
  .homeSlider .topLeft {
    top: 150px;
    left: 50px;
    text-align: left; }
  .homeSlider .topCenter {
    top: 150px;
    left: 0;
    right: 0;
    text-align: center; }
  .homeSlider .topRight {
    top: 150px;
    right: 50px;
    text-align: right; }
  .homeSlider .centerLeft {
    top: 50%;
    transform: translateY(-50%);
    left: 50px;
    text-align: left; }
  .homeSlider .centerCenter {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center; }
  .homeSlider .centerRight {
    top: 50%;
    transform: translateY(-50%);
    right: 50px;
    text-align: right; }
  .homeSlider .bottomLeft {
    bottom: 150px;
    left: 50px;
    text-align: left; }
  .homeSlider .bottomCenter {
    bottom: 150px;
    left: 0;
    right: 0;
    text-align: center; }
  .homeSlider .bottomRight {
    bottom: 150px;
    right: 50px;
    text-align: right; }
  .homeSlider__Show {
    margin-top: 200px;
    color: #FFF;
    float: left;
    width: 100%; }
    .homeSlider__Show i {
      transform: rotate(90deg); }
  .homeSlider .arrowPrev, .homeSlider .arrowNext {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 60px;
    height: 100%;
    z-index: 10000; }
  .homeSlider .arrowPrev {
    height: 100vh;
    line-height: 100vh;
    opacity: 1;
    transition: 200ms;
    color: #FFF;
    font-size: 40px;
    padding-left: 15px; }
    .homeSlider .arrowPrev:after {
      width: 70px;
      height: 100px;
      bottom: 45%;
      top: 45%;
      left: 0px;
      position: absolute;
      content: '';
      background: rgba(255, 255, 255, 0.3);
      text-align: center;
      transition: 200ms; }
    .homeSlider .arrowPrev:hover {
      transition: 200ms; }
      .homeSlider .arrowPrev:hover:before {
        opacity: 0.6;
        transition: 200ms; }
    .homeSlider .arrowPrev:before {
      line-height: 100vh;
      opacity: 1;
      font-size: 60px !important;
      z-index: 10000;
      color: #FFF;
      position: absolute;
      left: 20px;
      transition: 200ms; }
  .homeSlider .arrowNext {
    height: 100vh;
    line-height: 100vh;
    transition: 200ms;
    color: #FFF;
    font-size: 40px;
    opacity: 1;
    transition: 200ms;
    right: 0;
    text-align: right;
    padding-right: 15px; }
    .homeSlider .arrowNext:after {
      width: 70px;
      height: 100px;
      bottom: 45%;
      top: 45%;
      right: 0px;
      position: absolute;
      content: '';
      background: rgba(255, 255, 255, 0.3);
      text-align: center;
      transition: 200ms; }
    .homeSlider .arrowNext:hover {
      transition: 200ms; }
      .homeSlider .arrowNext:hover:before {
        opacity: 0.6;
        transition: 200ms; }
    .homeSlider .arrowNext:before {
      line-height: 100vh;
      opacity: 1;
      font-size: 60px !important;
      z-index: 10000;
      color: #FFF;
      position: absolute;
      right: 20px;
      transition: 200ms; }

.about {
  float: left;
  width: 100%;
  padding: 80px 0;
  background-size: 400px auto;
  background-repeat: no-repeat;
  background-position: 800px bottom;
  background-attachment: fixed; }
  .about h2 {
    color: #ff0000;
    margin: 0px;
    position: relative;
    z-index: 1;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    padding-top: 0px;
    padding-bottom: 20px;
    float: left;
    font-size: 70px;
    line-height: 37px; }
    .about h2:before {
      position: absolute;
      left: -4px;
      bottom: 0;
      content: '';
      z-index: -1;
      border-bottom: 10px solid #ffcdcd;
      right: 0; }
  .about__Cnt {
    float: left;
    width: 100%; }
  .about h3 {
    font-size: 31px; }
  .about p {
    color: #35394b;
    font-weight: lighter;
    font-size: 23px; }
  .about__More {
    padding: 0px 30px;
    text-transform: initial;
    color: #35394b;
    vertical-align: middle;
    border: 1px solid #35394b;
    border-top: 0;
    border-bottom: 0;
    position: relative;
    z-index: 1;
    line-height: 70px;
    outline: none;
    height: 68px;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: lighter;
    display: inline-block; }
    .about__More:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #35394b;
      z-index: -1;
      transition: 300ms; }
    .about__More:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #35394b;
      z-index: -1;
      transition: 300ms; }
    .about__More:hover:before {
      right: 30%; }
    .about__More:hover:after {
      left: 30%; }
  .about__Left {
    float: left;
    width: 70%; }
  .about__widgetsWrap {
    float: left;
    width: 100%;
    margin-top: 50px; }
  .about__Widgets {
    float: left;
    width: 31%;
    margin-left: 1%;
    margin-right: 1%; }
    .about__Widgets h3 {
      font-size: 30px;
      margin: 0;
      color: #35394b; }
    .about__Widgets p {
      color: #35394b; }

.news {
  float: left;
  width: 100%;
  text-align: center;
  padding: 80px 0; }
  .news__Clear {
    float: left;
    width: 100%;
    margin-top: 60px; }
  .news__More {
    padding: 20px 30px;
    text-transform: initial;
    color: #00125f;
    vertical-align: middle;
    border: 1px solid #00125f;
    border-top: 0;
    border-bottom: 0;
    position: relative;
    z-index: 1;
    line-height: 70px;
    outline: none;
    height: 70px;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: lighter; }
    .news__More:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #00125f;
      z-index: -1;
      transition: 300ms; }
    .news__More:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #00125f;
      z-index: -1;
      transition: 300ms; }
    .news__More:hover:before {
      right: 30%; }
    .news__More:hover:after {
      left: 30%; }
  .news h2 {
    color: #ff0000;
    margin: 0px;
    position: relative;
    z-index: 1;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    padding-top: 0px;
    padding-bottom: 20px;
    float: left;
    font-size: 70px;
    line-height: 37px; }
    .news h2:before {
      position: absolute;
      left: -4px;
      bottom: 0;
      content: '';
      z-index: -1;
      border-bottom: 10px solid #ffcdcd;
      right: 0; }
  .news h3 {
    font-size: 31px;
    float: left;
    clear: both; }
  .news__Cnt {
    float: left;
    width: 100%; }
  .news__Box {
    float: left;
    display: block;
    margin-right: 3.44828%;
    width: 31.03448%;
    text-align: left; }
    .news__Box:last-child {
      margin-right: 0; }
    .news__Box:nth-of-type(3n) {
      margin-right: 0; }
    .news__Box--img {
      float: left;
      width: 100%;
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center; }
    .news__Box--cnt {
      float: left;
      width: 100%; }
    .news__Box a {
      text-decoration: none; }
      .news__Box a:hover p {
        color: #000; }
    .news__Box h4 {
      float: left;
      width: 100%;
      color: #35394b;
      font-size: 24px;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 0; }
    .news__Box h6 {
      color: #35394b;
      margin: 0; }
    .news__Box p {
      color: #35394b;
      font-size: 14px; }
    .news__Box--more {
      padding: 10px 20px;
      text-transform: initial;
      color: #35394b;
      vertical-align: middle;
      border: 1px solid #35394b;
      border-top: 0;
      border-bottom: 0;
      position: relative;
      z-index: 1;
      line-height: 70px;
      outline: none;
      height: 70px;
      text-decoration: none;
      font-family: "Montserrat", sans-serif;
      font-weight: lighter; }
      .news__Box--more:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: #35394b;
        z-index: -1;
        transition: 300ms; }
      .news__Box--more:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: #35394b;
        z-index: -1;
        transition: 300ms; }
      .news__Box--more:hover:before {
        right: 30%; }
      .news__Box--more:hover:after {
        left: 30%; }

.getInTouch {
  float: left;
  width: 100%;
  padding: 80px 0;
  height: 400px;
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: fixed; }
  .getInTouch h2 {
    font-size: 45px;
    color: #FFF;
    margin: 0px;
    position: relative;
    z-index: 1;
    font-weight: bold; }
  .getInTouch p {
    font-size: 21px;
    color: #FFF; }
  .getInTouch__Left {
    float: left;
    width: 59%;
    margin-right: 1%; }
  .getInTouch__Right {
    float: left;
    width: 39%;
    margin-left: 1%;
    position: relative; }
    .getInTouch__Right input {
      height: 50px;
      line-height: 50px;
      float: left;
      width: 80%;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #FFF;
      background-color: #FFF;
      color: #35394b;
      font-size: 18px; }
    .getInTouch__Right textarea {
      max-width: 100%;
      min-width: 100%;
      float: left;
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      height: 200px;
      border: 1px solid #35394b;
      background-color: transparent; }
    .getInTouch__Right input[type="submit"] {
      background: transparent;
      border: 0;
      margin: 0;
      color: #FFF;
      line-height: 36px;
      padding: 0;
      width: 100%; }
    .getInTouch__Right div.wpcf7-response-output {
      margin: 0 !important;
      float: left;
      position: absolute;
      top: 90px;
      left: 0;
      color: #fff; }
    .getInTouch__Right .contactButton {
      border: 1px solid #FFF;
      border-top: 0;
      border-bottom: 0;
      position: relative;
      z-index: 1;
      outline: none;
      height: 50px;
      float: left;
      width: 19%;
      margin-left: 1%; }
      .getInTouch__Right .contactButton:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: #FFF;
        z-index: -1;
        transition: 300ms; }
      .getInTouch__Right .contactButton:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: #FFF;
        z-index: -1;
        transition: 300ms; }
      .getInTouch__Right .contactButton:hover:before {
        right: 30%; }
      .getInTouch__Right .contactButton:hover:after {
        left: 30%; }

.clients {
  float: left;
  width: 100%;
  padding: 80px 0; }
  .clients h2 {
    color: #ff0000;
    margin: 0px;
    position: relative;
    z-index: 1;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    padding-top: 0px;
    padding-bottom: 20px;
    display: inline-block;
    font-size: 30px;
    line-height: 17px; }
    .clients h2:before {
      position: absolute;
      left: -4px;
      bottom: 0;
      content: '';
      z-index: -1;
      border-bottom: 5px solid #ffcdcd;
      right: 0; }
    .clients h2:hover:before {
      border-color: #ff0000; }
  .clients a {
    text-decoration: none; }
  .clients__Clear {
    float: left;
    width: 100%;
    margin-bottom: 30px;
    text-align: center; }
  .clients ul {
    padding: 0;
    margin: 0;
    margin-bottom: 0 !important;
    float: left;
    width: 100%; }
    .clients ul li {
      list-style: none;
      display: inline-block;
      text-align: center;
      height: 100px;
      line-height: 100px;
      width: 16%;
      float: left; }
      .clients ul li img {
        max-height: 60px;
        vertical-align: middle;
        max-width: 145px;
        opacity: 1;
        filter: grayscale(100%); }
      .clients ul li a {
        text-decoration: none; }
        .clients ul li a:hover img {
          opacity: 0.6; }

.advice {
  float: left;
  width: 100%;
  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
  background-position: center center;
  background-attachment: fixed; }
  .advice .center {
    height: 400px; }
  .advice:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: 0.5; }
  .advice__Inner {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center; }
  .advice h2 {
    font-size: 45px;
    color: #FFF;
    margin: 0px;
    position: relative;
    z-index: 1;
    font-weight: bold;
    margin-bottom: 20px; }
  .advice__More {
    padding: 0px 30px;
    text-transform: initial;
    color: #FFF;
    vertical-align: middle;
    border: 1px solid #FFF;
    border-top: 0;
    border-bottom: 0;
    position: relative;
    z-index: 1;
    line-height: 70px;
    outline: none;
    height: 68px;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: lighter;
    display: inline-block; }
    .advice__More:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #FFF;
      z-index: -1;
      transition: 300ms; }
    .advice__More:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #FFF;
      z-index: -1;
      transition: 300ms; }
    .advice__More:hover:before {
      right: 30%; }
    .advice__More:hover:after {
      left: 30%; }

.centerMe {
  text-align: center; }

.blog {
  float: left;
  width: 65%;
  text-align: center;
  padding: 0 0 80px 0; }
  .blog__Clear {
    float: left;
    width: 100%;
    margin-top: 60px; }
  .blog__More {
    padding: 20px 30px;
    text-transform: initial;
    color: #00125f;
    vertical-align: middle;
    border: 1px solid #00125f;
    border-top: 0;
    border-bottom: 0;
    position: relative;
    z-index: 1;
    line-height: 70px;
    outline: none;
    height: 70px;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: lighter; }
    .blog__More:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #00125f;
      z-index: -1;
      transition: 300ms; }
    .blog__More:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #00125f;
      z-index: -1;
      transition: 300ms; }
    .blog__More:hover:before {
      right: 30%; }
    .blog__More:hover:after {
      left: 30%; }
  .blog__Nav .news__More {
    display: inline-block;
    line-height: initial;
    height: auto;
    padding: 0px 30px; }
  .blog__Cnt {
    float: left;
    width: 100%; }
  .blog__Box {
    float: left;
    display: block;
    margin-right: 3.44828%;
    width: 48.27586%;
    text-align: left;
    margin-bottom: 20px; }
    .blog__Box:last-child {
      margin-right: 0; }
    .blog__Box:nth-of-type(2n) {
      margin-right: 0; }
    .blog__Box--img {
      float: left;
      width: 100%;
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center; }
    .blog__Box--cnt {
      float: left;
      width: 100%; }
    .blog__Box a {
      text-decoration: none; }
      .blog__Box a:hover p {
        color: #000; }
    .blog__Box h4 {
      float: left;
      width: 100%;
      color: #35394b;
      font-size: 24px;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 0; }
    .blog__Box h6 {
      color: #35394b;
      margin: 0; }
    .blog__Box p {
      color: #35394b;
      font-size: 14px; }
    .blog__Box--more {
      padding: 10px 20px;
      text-transform: initial;
      color: #35394b;
      vertical-align: middle;
      border: 1px solid #35394b;
      border-top: 0;
      border-bottom: 0;
      position: relative;
      z-index: 1;
      line-height: 70px;
      outline: none;
      height: 70px;
      text-decoration: none;
      font-family: "Montserrat", sans-serif;
      font-weight: lighter; }
      .blog__Box--more:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: #35394b;
        z-index: -1;
        transition: 300ms; }
      .blog__Box--more:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: #35394b;
        z-index: -1;
        transition: 300ms; }
      .blog__Box--more:hover:before {
        right: 30%; }
      .blog__Box--more:hover:after {
        left: 30%; }
  .blog .paginationWrap {
    text-align: center;
    float: left;
    width: 100%;
    padding: 40px 0; }
    .blog .paginationWrap span {
      float: left;
      width: 100%;
      text-align: center; }

.sidebar {
  float: right;
  width: 30%; }
  .sidebar ul {
    padding: 0;
    margin: 0; }
  .sidebar h2 {
    float: left;
    width: 100%;
    color: #ff0000;
    font-size: 24px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    margin: 0;
    margin-bottom: 10px; }
  .sidebar .widget {
    float: left;
    width: 100%;
    margin-bottom: 20px; }
  .sidebar .screen-reader-text {
    display: none; }
  .sidebar input {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    line-height: 40px; }
  .sidebar input[type="submit"] {
    float: left;
    width: auto;
    margin-top: 10px;
    background: #FFF;
    border: 1px solid #000;
    padding: 0 30px; }
  .sidebar #searchsubmit {
    margin-bottom: 10px; }
  .sidebar a {
    text-decoration: none;
    color: #35394b; }
    .sidebar a:hover {
      color: #000; }
  .sidebar li {
    list-style: none;
    margin-bottom: 5px; }

.awwards {
  float: left;
  width: 100%;
  text-align: center;
  padding: 0 0 80px 0; }
  .awwards__Clear {
    float: left;
    width: 100%;
    margin-top: 60px; }
  .awwards__More {
    padding: 20px 30px;
    text-transform: initial;
    color: #00125f;
    vertical-align: middle;
    border: 1px solid #00125f;
    border-top: 0;
    border-bottom: 0;
    position: relative;
    z-index: 1;
    line-height: 70px;
    outline: none;
    height: 70px;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: lighter; }
    .awwards__More:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #00125f;
      z-index: -1;
      transition: 300ms; }
    .awwards__More:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #00125f;
      z-index: -1;
      transition: 300ms; }
    .awwards__More:hover:before {
      right: 30%; }
    .awwards__More:hover:after {
      left: 30%; }
  .awwards__Nav .news__More {
    display: inline-block;
    line-height: initial;
    height: auto;
    padding: 0px 30px; }
  .awwards__Cnt {
    float: left;
    width: 100%; }
  .awwards__Box {
    float: left;
    display: block;
    margin-right: 3.44828%;
    width: 48.27586%;
    text-align: left;
    margin-bottom: 70px; }
    .awwards__Box:last-child {
      margin-right: 0; }
    .awwards__Box:nth-of-type(2n) {
      margin-right: 0; }
    .awwards__Box--img {
      float: left;
      width: 100%;
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center; }
    .awwards__Box--cnt {
      float: left;
      width: 100%; }
    .awwards__Box a {
      text-decoration: none; }
      .awwards__Box a:hover p {
        color: #000; }
    .awwards__Box h4 {
      float: left;
      width: 100%;
      color: #35394b;
      font-size: 24px;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 0; }
    .awwards__Box h6 {
      color: #35394b;
      margin: 0; }
    .awwards__Box p {
      color: #35394b;
      font-size: 14px; }
    .awwards__Box--more {
      padding: 10px 20px;
      text-transform: initial;
      color: #35394b;
      vertical-align: middle;
      border: 1px solid #35394b;
      border-top: 0;
      border-bottom: 0;
      position: relative;
      z-index: 1;
      line-height: 70px;
      outline: none;
      height: 70px;
      text-decoration: none;
      font-family: "Montserrat", sans-serif;
      font-weight: lighter; }
      .awwards__Box--more:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: #35394b;
        z-index: -1;
        transition: 300ms; }
      .awwards__Box--more:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: #35394b;
        z-index: -1;
        transition: 300ms; }
      .awwards__Box--more:hover:before {
        right: 30%; }
      .awwards__Box--more:hover:after {
        left: 30%; }
  .awwards .paginationWrap {
    text-align: center;
    float: left;
    width: 100%;
    padding: 40px 0; }
    .awwards .paginationWrap span {
      float: left;
      width: 100%;
      text-align: center; }

.postSingle {
  float: left;
  width: 100%;
  background: #FFF; }
  .postSingle__Content {
    overflow: hidden;
    position: relative;
    padding-bottom: 100px; }
  .postSingle__Top {
    width: 100%;
    margin: auto;
    text-align: left;
    padding: 70px 0;
    background: #ff0000; }
    .postSingle__Top h2 {
      margin: 0;
      font-size: 48px;
      color: #FFF;
      font-weight: lighter;
      line-height: 1.2;
      position: relative;
      z-index: 1;
      text-transform: uppercase;
      max-width: 665px;
      margin: auto; }
    .postSingle__Top h6 {
      color: #FFF;
      max-width: 665px;
      margin: auto; }
    .postSingle__Top a {
      text-decoration: none; }
  .postSingle__Cover {
    width: 100%;
    height: 450px;
    margin: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1; }
    .postSingle__Cover:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #000;
      opacity: 0.4;
      z-index: -1; }
  .postSingle__Left {
    float: left;
    width: 49%;
    margin-right: 1%; }
    .postSingle__Left h1 {
      font-size: 48px;
      line-height: 1.2;
      font-weight: lighter; }
    .postSingle__Left h2 {
      font-size: 26px;
      line-height: 1.4;
      font-weight: lighter; }
    .postSingle__Left h3 {
      font-size: 22px;
      font-weight: bold; }
    .postSingle__Left h4, .postSingle__Left h5, .postSingle__Left h6 {
      font-size: 16px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Left .wp-caption {
      width: auto !important; }
      .postSingle__Left .wp-caption p {
        font-size: 12px;
        font-weight: lighter;
        color: #ff0000; }
    .postSingle__Left p {
      font-size: 16px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Left li {
      font-size: 16px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Left span {
      font-size: 16px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Left a {
      font-size: 16px;
      line-height: 1.6;
      color: #ff0000;
      text-decoration: none; }
    .postSingle__Left mark {
      background: #e7f5fe; }
    .postSingle__Left blockquote {
      background: #e7f5fe;
      font-weight: lighter;
      font-size: 16px;
      padding: 25px;
      color: #004e99;
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      line-height: 1.8;
      margin-bottom: 60px; }
      .postSingle__Left blockquote p {
        margin: 0;
        line-height: 1.8;
        color: #004e99; }
    .postSingle__Left--intro {
      margin-top: 60px;
      margin-bottom: 60px; }
      .postSingle__Left--intro p {
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
      .postSingle__Left--intro a {
        color: #ff0000;
        border-bottom: 1px dotted #ff0000;
        text-decoration: none;
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
        .postSingle__Left--intro a:hover {
          border-bottom: 1px solid #ff0000; }
    .postSingle__Left input {
      width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #000;
      font-weight: lighter;
      line-height: 46px;
      font-size: 14px; }
    .postSingle__Left textarea {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #000;
      font-weight: lighter;
      font-size: 14px; }
    .postSingle__Left input[type="submit"] {
      border: 1px #dedede solid;
      display: inline-block;
      padding: 0 30px;
      line-height: 46px;
      text-decoration: none;
      font-size: 14px;
      color: #dedede;
      position: relative;
      overflow: hidden;
      z-index: 1;
      vertical-align: bottom;
      background: #dedede;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
      letter-spacing: 1px;
      font-weight: bold;
      color: #000; }
      .postSingle__Left input[type="submit"]:hover {
        color: #ff0000; }
        .postSingle__Left input[type="submit"]:hover:before {
          opacity: 1;
          right: -100px;
          left: -100px; }
      .postSingle__Left input[type="submit"]:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        top: -10px;
        bottom: -10px;
        transform: skew(40deg);
        background: #FFF;
        left: 100%;
        right: 110%;
        transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
      .postSingle__Left input[type="submit"]:hover {
        background: #ff0000;
        color: #000; }
  .postSingle__Right {
    float: left;
    width: 49%;
    margin-left: 1%; }
    .postSingle__Right h1 {
      font-size: 48px;
      line-height: 1.2;
      font-weight: lighter; }
    .postSingle__Right h2 {
      font-size: 26px;
      line-height: 1.4;
      font-weight: lighter; }
    .postSingle__Right h3 {
      font-size: 22px;
      font-weight: bold; }
    .postSingle__Right h4, .postSingle__Right h5, .postSingle__Right h6 {
      font-size: 16px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Right .wp-caption {
      width: auto !important; }
      .postSingle__Right .wp-caption p {
        font-size: 12px;
        font-weight: lighter;
        color: #ff0000; }
    .postSingle__Right p {
      font-size: 16px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Right li {
      font-size: 16px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Right span {
      font-size: 16px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Right a {
      font-size: 16px;
      line-height: 1.6;
      color: #ff0000;
      text-decoration: none; }
    .postSingle__Right mark {
      background: #e7f5fe; }
    .postSingle__Right blockquote {
      background: #e7f5fe;
      font-weight: lighter;
      font-size: 16px;
      padding: 25px;
      color: #004e99;
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      line-height: 1.8;
      margin-bottom: 60px; }
      .postSingle__Right blockquote p {
        margin: 0;
        line-height: 1.8;
        color: #004e99; }
    .postSingle__Right--intro {
      margin-top: 60px;
      margin-bottom: 60px; }
      .postSingle__Right--intro p {
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
      .postSingle__Right--intro a {
        color: #ff0000;
        border-bottom: 1px dotted #ff0000;
        text-decoration: none;
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
        .postSingle__Right--intro a:hover {
          border-bottom: 1px solid #ff0000; }
    .postSingle__Right input {
      width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #000;
      font-weight: lighter;
      line-height: 46px;
      font-size: 14px; }
    .postSingle__Right textarea {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #000;
      font-weight: lighter;
      font-size: 14px; }
    .postSingle__Right input[type="submit"] {
      border: 1px #dedede solid;
      display: inline-block;
      padding: 0 30px;
      line-height: 46px;
      text-decoration: none;
      font-size: 14px;
      color: #dedede;
      position: relative;
      overflow: hidden;
      z-index: 1;
      vertical-align: bottom;
      background: #dedede;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
      letter-spacing: 1px;
      font-weight: bold;
      color: #000; }
      .postSingle__Right input[type="submit"]:hover {
        color: #ff0000; }
        .postSingle__Right input[type="submit"]:hover:before {
          opacity: 1;
          right: -100px;
          left: -100px; }
      .postSingle__Right input[type="submit"]:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        top: -10px;
        bottom: -10px;
        transform: skew(40deg);
        background: #FFF;
        left: 100%;
        right: 110%;
        transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
      .postSingle__Right input[type="submit"]:hover {
        background: #ff0000;
        color: #000; }
  .postSingle__Inner {
    max-width: 665px;
    margin: auto;
    margin-top: 70px; }
    .postSingle__Inner h1 {
      font-size: 48px;
      line-height: 1.2;
      font-weight: lighter; }
    .postSingle__Inner h2 {
      font-size: 26px;
      line-height: 1.4;
      font-weight: lighter; }
    .postSingle__Inner h3 {
      font-size: 22px;
      font-weight: bold; }
    .postSingle__Inner h4, .postSingle__Inner h5 {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Inner .wp-caption {
      width: auto !important; }
      .postSingle__Inner .wp-caption p {
        font-size: 12px;
        font-weight: lighter;
        color: #ff0000; }
    .postSingle__Inner p {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Inner li {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Inner span {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Inner a {
      font-size: 18px;
      line-height: 1.6;
      color: #ff0000;
      text-decoration: none; }
    .postSingle__Inner mark {
      background: #e7f5fe; }
    .postSingle__Inner blockquote {
      background: #e7f5fe;
      font-weight: lighter;
      font-size: 16px;
      padding: 25px;
      color: #004e99;
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      line-height: 1.8;
      margin-bottom: 60px; }
      .postSingle__Inner blockquote p {
        margin: 0;
        line-height: 1.8;
        color: #004e99; }
    .postSingle__Inner--intro {
      margin-top: 60px;
      margin-bottom: 60px; }
      .postSingle__Inner--intro p {
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
      .postSingle__Inner--intro a {
        color: #ff0000;
        border-bottom: 1px dotted #ff0000;
        text-decoration: none;
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
        .postSingle__Inner--intro a:hover {
          border-bottom: 1px solid #ff0000; }
    .postSingle__Inner input {
      width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #000;
      font-weight: lighter;
      line-height: 46px;
      font-size: 14px; }
    .postSingle__Inner textarea {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #000;
      font-weight: lighter;
      font-size: 14px; }
    .postSingle__Inner input[type="submit"] {
      border: 1px #dedede solid;
      display: inline-block;
      padding: 0 30px;
      line-height: 46px;
      text-decoration: none;
      font-size: 14px;
      color: #dedede;
      position: relative;
      overflow: hidden;
      z-index: 1;
      vertical-align: bottom;
      background: #dedede;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
      letter-spacing: 1px;
      font-weight: bold;
      color: #000; }
      .postSingle__Inner input[type="submit"]:hover {
        color: #ff0000; }
        .postSingle__Inner input[type="submit"]:hover:before {
          opacity: 1;
          right: -100px;
          left: -100px; }
      .postSingle__Inner input[type="submit"]:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        top: -10px;
        bottom: -10px;
        transform: skew(40deg);
        background: #FFF;
        left: 100%;
        right: 110%;
        transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
      .postSingle__Inner input[type="submit"]:hover {
        background: #ff0000;
        color: #000; }
  .postSingle__InnerWithNo {
    max-width: 665px;
    margin: auto;
    margin-top: 70px; }
    .postSingle__InnerWithNo h1 {
      font-size: 48px;
      line-height: 1.2;
      font-weight: lighter; }
    .postSingle__InnerWithNo h2 {
      font-size: 26px;
      line-height: 1.4;
      font-weight: lighter; }
    .postSingle__InnerWithNo h3 {
      font-size: 22px;
      font-weight: bold; }
    .postSingle__InnerWithNo h4, .postSingle__InnerWithNo h5, .postSingle__InnerWithNo h6 {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .postSingle__InnerWithNo .wp-caption {
      width: auto !important; }
      .postSingle__InnerWithNo .wp-caption p {
        font-size: 12px;
        font-weight: lighter;
        color: #ff0000; }
    .postSingle__InnerWithNo p {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .postSingle__InnerWithNo li {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .postSingle__InnerWithNo span {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .postSingle__InnerWithNo--cnt a {
      font-size: 18px;
      line-height: 1.6;
      color: #ff0000;
      text-decoration: none; }
    .postSingle__InnerWithNo mark {
      background: #e7f5fe; }
    .postSingle__InnerWithNo blockquote {
      background: #e7f5fe;
      font-weight: lighter;
      font-size: 16px;
      padding: 25px;
      color: #004e99;
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      line-height: 1.8;
      margin-bottom: 60px; }
      .postSingle__InnerWithNo blockquote p {
        margin: 0;
        line-height: 1.8;
        color: #004e99; }
    .postSingle__InnerWithNo--intro {
      margin-top: 60px;
      margin-bottom: 60px; }
      .postSingle__InnerWithNo--intro p {
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
      .postSingle__InnerWithNo--intro a {
        color: #ff0000;
        border-bottom: 1px dotted #ff0000;
        text-decoration: none;
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
        .postSingle__InnerWithNo--intro a:hover {
          border-bottom: 1px solid #ff0000; }
    .postSingle__InnerWithNo input {
      width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #000;
      font-weight: lighter;
      line-height: 46px;
      font-size: 14px; }
    .postSingle__InnerWithNo textarea {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #000;
      font-weight: lighter;
      font-size: 14px; }
    .postSingle__InnerWithNo input[type="submit"] {
      border: 1px #dedede solid;
      display: inline-block;
      padding: 0 30px;
      line-height: 46px;
      text-decoration: none;
      font-size: 14px;
      color: #dedede;
      position: relative;
      overflow: hidden;
      z-index: 1;
      vertical-align: bottom;
      background: #dedede;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
      letter-spacing: 1px;
      font-weight: bold;
      color: #000; }
      .postSingle__InnerWithNo input[type="submit"]:hover {
        color: #ff0000; }
        .postSingle__InnerWithNo input[type="submit"]:hover:before {
          opacity: 1;
          right: -100px;
          left: -100px; }
      .postSingle__InnerWithNo input[type="submit"]:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        top: -10px;
        bottom: -10px;
        transform: skew(40deg);
        background: #FFF;
        left: 100%;
        right: 110%;
        transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
      .postSingle__InnerWithNo input[type="submit"]:hover {
        background: #ff0000;
        color: #000; }
  .postSingle__Full {
    margin-top: 70px; }
    .postSingle__Full h1 {
      font-size: 48px;
      line-height: 1.2;
      font-weight: lighter; }
    .postSingle__Full h2 {
      font-size: 26px;
      line-height: 1.4;
      font-weight: lighter; }
    .postSingle__Full h3 {
      font-size: 22px;
      font-weight: bold; }
    .postSingle__Full h4, .postSingle__Full h5, .postSingle__Full h6 {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Full .wp-caption {
      width: auto !important; }
      .postSingle__Full .wp-caption p {
        font-size: 12px;
        font-weight: lighter;
        color: #ff0000; }
    .postSingle__Full p {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Full li {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Full span {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Full a {
      font-size: 18px;
      line-height: 1.6;
      color: #ff0000;
      text-decoration: none; }
    .postSingle__Full mark {
      background: #e7f5fe; }
    .postSingle__Full blockquote {
      background: #e7f5fe;
      font-weight: lighter;
      font-size: 16px;
      padding: 25px;
      color: #004e99;
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      line-height: 1.8;
      margin-bottom: 60px; }
      .postSingle__Full blockquote p {
        margin: 0;
        line-height: 1.8;
        color: #004e99; }
    .postSingle__Full--intro {
      margin-top: 60px;
      margin-bottom: 60px; }
      .postSingle__Full--intro p {
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
      .postSingle__Full--intro a {
        color: #ff0000;
        border-bottom: 1px dotted #ff0000;
        text-decoration: none;
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
        .postSingle__Full--intro a:hover {
          border-bottom: 1px solid #ff0000; }
    .postSingle__Full input {
      width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #000;
      font-weight: lighter;
      line-height: 46px;
      font-size: 14px; }
    .postSingle__Full textarea {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #000;
      font-weight: lighter;
      font-size: 14px; }
    .postSingle__Full input[type="submit"] {
      border: 1px #dedede solid;
      display: inline-block;
      padding: 0 30px;
      line-height: 46px;
      text-decoration: none;
      font-size: 14px;
      color: #dedede;
      position: relative;
      overflow: hidden;
      z-index: 1;
      vertical-align: bottom;
      background: #dedede;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
      letter-spacing: 1px;
      font-weight: bold;
      color: #000; }
      .postSingle__Full input[type="submit"]:hover {
        color: #ff0000; }
        .postSingle__Full input[type="submit"]:hover:before {
          opacity: 1;
          right: -100px;
          left: -100px; }
      .postSingle__Full input[type="submit"]:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        top: -10px;
        bottom: -10px;
        transform: skew(40deg);
        background: #FFF;
        left: 100%;
        right: 110%;
        transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
      .postSingle__Full input[type="submit"]:hover {
        background: #ff0000;
        color: #000; }

.relatedPosts {
  padding-bottom: 150px;
  float: left;
  width: 100%;
  margin-top: 100px; }
  .relatedPosts__Title {
    margin: 0;
    font-size: 36px;
    color: #000;
    font-weight: lighter;
    line-height: 1.2;
    max-width: 800px;
    margin: auto;
    margin-bottom: 90px;
    text-align: center; }
  .relatedPosts__Subtitle {
    margin: 0;
    font-size: 12px;
    letter-spacing: 2px;
    text-align: center;
    color: #ff0000;
    margin: 0;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: normal; }
  .relatedPosts__Inner {
    max-width: 600px;
    margin: auto; }
  .relatedPosts__Box {
    border-radius: 6px;
    padding: 50px 0px;
    background: #FFF;
    position: relative;
    z-index: 1;
    float: left;
    width: 100%; }
    .relatedPosts__Box h2 {
      font-weight: lighter;
      margin: 0;
      color: #000;
      font-size: 28px;
      line-height: 1.3;
      margin-bottom: 20px;
      transition: 300ms; }
      .relatedPosts__Box h2:hover {
        color: #004e99; }
    .relatedPosts__Box h4 {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 1.1;
      color: #ff0000;
      font-weight: bold;
      margin: 0;
      margin-bottom: 10px; }
    .relatedPosts__Box p {
      font-size: 18px;
      color: #000;
      font-weight: lighter;
      margin: 0;
      line-height: 1.6; }
    .relatedPosts__Box:before {
      left: 0px;
      right: 0;
      bottom: 0;
      height: 1px;
      background: #f0f0f0;
      position: absolute;
      content: '';
      z-index: -1; }
    .relatedPosts__Box a {
      text-decoration: none;
      transition: 300ms; }
    .relatedPosts__Box:last-of-type:before {
      display: none; }

.postSingleGray {
  float: left;
  width: 100%;
  background: #FFF; }
  .postSingleGray__Content {
    overflow: hidden;
    position: relative; }
  .postSingleGray__Top {
    width: 100%;
    margin: auto;
    text-align: left;
    padding: 70px 0;
    background: #00125f; }
    .postSingleGray__Top h2 {
      margin: 0;
      font-size: 48px;
      color: #000;
      font-weight: lighter;
      line-height: 1.2;
      position: relative;
      z-index: 1;
      text-transform: uppercase; }
    .postSingleGray__Top a {
      text-decoration: none; }
  .postSingleGray__locationMap {
    height: 950px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    width: 100%;
    background-color: #00125f;
    margin-top: 60px; }
  .postSingleGray__Cover {
    width: 100%;
    height: 450px;
    margin: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-bottom: 20px solid #ff0000; }
  .postSingleGray__Inner h1 {
    font-size: 48px;
    line-height: 1.2;
    font-weight: lighter; }
  .postSingleGray__Inner h2 {
    font-size: 26px;
    line-height: 1.4;
    font-weight: lighter; }
  .postSingleGray__Inner h3 {
    font-size: 22px;
    font-weight: bold; }
  .postSingleGray__Inner h4, .postSingleGray__Inner h5, .postSingleGray__Inner h6 {
    font-size: 18px;
    line-height: 1.6;
    color: #000; }
  .postSingleGray__Inner .wp-caption {
    width: auto !important; }
    .postSingleGray__Inner .wp-caption p {
      font-size: 12px;
      font-weight: lighter;
      color: #ff0000; }
  .postSingleGray__Inner p {
    font-size: 18px;
    line-height: 1.6;
    color: #000; }
  .postSingleGray__Inner li {
    font-size: 18px;
    line-height: 1.6;
    color: #000; }
  .postSingleGray__Inner span {
    font-size: 18px;
    line-height: 1.6;
    color: #000; }
  .postSingleGray__Inner a {
    font-size: 18px;
    line-height: 1.6;
    color: #ff0000;
    text-decoration: none; }
  .postSingleGray__Inner mark {
    background: #e7f5fe; }
  .postSingleGray__Inner blockquote {
    background: #e7f5fe;
    font-weight: lighter;
    font-size: 16px;
    padding: 25px;
    color: #004e99;
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    line-height: 1.8;
    margin-bottom: 60px; }
    .postSingleGray__Inner blockquote p {
      margin: 0;
      line-height: 1.8;
      color: #004e99; }
  .postSingleGray__Inner--intro {
    margin-top: 60px;
    margin-bottom: 60px; }
    .postSingleGray__Inner--intro p {
      font-size: 26px;
      line-height: 1.4;
      font-weight: lighter; }
    .postSingleGray__Inner--intro a {
      color: #ff0000;
      border-bottom: 1px dotted #ff0000;
      text-decoration: none;
      font-size: 26px;
      line-height: 1.4;
      font-weight: lighter; }
      .postSingleGray__Inner--intro a:hover {
        border-bottom: 1px solid #ff0000; }
  .postSingleGray__Inner input {
    width: 100%;
    border: 1px solid #dedede;
    padding: 0 10px;
    color: #000;
    font-weight: lighter;
    line-height: 46px;
    font-size: 14px; }
  .postSingleGray__Inner textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border: 1px solid #dedede;
    padding: 0 10px;
    color: #000;
    font-weight: lighter;
    font-size: 14px; }
  .postSingleGray__Inner input[type="submit"] {
    border: 1px #dedede solid;
    display: inline-block;
    padding: 0 30px;
    line-height: 46px;
    text-decoration: none;
    font-size: 14px;
    color: #dedede;
    position: relative;
    overflow: hidden;
    z-index: 1;
    vertical-align: bottom;
    background: #dedede;
    transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
    letter-spacing: 1px;
    font-weight: bold;
    color: #000; }
    .postSingleGray__Inner input[type="submit"]:hover {
      color: #ff0000; }
      .postSingleGray__Inner input[type="submit"]:hover:before {
        opacity: 1;
        right: -100px;
        left: -100px; }
    .postSingleGray__Inner input[type="submit"]:before {
      content: '';
      display: block;
      z-index: -1;
      position: absolute;
      top: -10px;
      bottom: -10px;
      transform: skew(40deg);
      background: #FFF;
      left: 100%;
      right: 110%;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
    .postSingleGray__Inner input[type="submit"]:hover {
      background: #ff0000;
      color: #000; }

.locations {
  float: left;
  width: 100%; }
  .locations h2 {
    text-align: center;
    font-weight: lighter;
    font-size: 60px;
    margin: 0; }
  .locations__Nav {
    text-align: center;
    height: 50px;
    line-height: 50px; }
    .locations__Nav ul {
      padding: 0;
      margin: 0;
      list-style: none; }
    .locations__Nav li {
      display: inline-block;
      color: #000;
      transition: 300ms;
      position: relative;
      padding: 0 10px;
      z-index: 1; }
      .locations__Nav li:after {
        content: '';
        position: absolute;
        top: 10px;
        bottom: 10px;
        right: -5px;
        width: 1px;
        background: #e2e2e2;
        z-index: -1; }
      .locations__Nav li:last-of-type:after {
        display: none; }
      .locations__Nav li:hover {
        cursor: pointer;
        color: #ff0000; }
    .locations__Nav li.locationCatActive {
      color: #ff0000; }
  .locations__Wrap {
    height: 300px;
    margin: auto;
    width: 100%;
    max-width: 860px; }
  .locations__Box {
    float: left;
    width: 100%;
    position: relative; }
    .locations__Box .center {
      position: relative;
      min-height: 400px;
      padding-left: 100px;
      padding-right: 100px;
      padding: 50px 0; }
    .locations__Box a {
      text-decoration: none; }
    .locations__Box:nth-of-type(2n) {
      background: #00125f; }
    .locations__Box--img {
      float: left;
      width: 30%;
      height: 300px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      position: relative; }
      .locations__Box--img a {
        float: left;
        width: 100%;
        height: 200px; }
    .locations__Box--content {
      width: 65%;
      margin-left: 5%;
      float: left;
      position: relative; }
      .locations__Box--content h4 {
        font-size: 30px;
        color: #000;
        font-weight: bold;
        margin: 0;
        margin-top: -10px;
        margin-bottom: 10px; }
      .locations__Box--content p {
        color: #ff0000;
        margin: 2px 0; }
      .locations__Box--content .lastParagraph {
        margin-top: 20px;
        margin-bottom: 40px; }
      .locations__Box--content span {
        color: #000;
        font-weight: bold;
        margin-right: 3px; }
      .locations__Box--content--more {
        background: #35394b;
        color: #ff0000;
        text-transform: uppercase;
        padding-left: 40px;
        padding-right: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
        border: 0;
        display: inline-block;
        text-decoration: none;
        transition: 300ms; }
        .locations__Box--content--more:hover {
          color: #FFF;
          background: #ff0000; }

.partsSingle {
  float: left;
  width: 100%;
  background: #FFF; }
  .partsSingle__Content {
    overflow: hidden;
    position: relative; }
  .partsSingle__Top {
    width: 100%;
    margin: auto;
    text-align: left;
    padding: 70px 0;
    background: #ff0000; }
    .partsSingle__Top h2 {
      margin: 0;
      font-size: 48px;
      color: #FFF;
      font-weight: lighter;
      line-height: 1.2;
      position: relative;
      z-index: 1;
      text-transform: uppercase; }
    .partsSingle__Top a {
      text-decoration: none; }
  .partsSingle__Cover {
    width: 100%;
    height: 450px;
    margin: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-bottom: 20px solid #ff0000; }
  .partsSingle__Inner {
    margin-top: 70px; }
    .partsSingle__Inner h1 {
      font-size: 48px;
      line-height: 1.2;
      font-weight: lighter;
      color: #FFF; }
    .partsSingle__Inner h2 {
      font-size: 26px;
      line-height: 1.4;
      font-weight: lighter;
      color: #FFF; }
    .partsSingle__Inner h3 {
      font-size: 22px;
      font-weight: bold;
      color: #FFF; }
    .partsSingle__Inner h4, .partsSingle__Inner h5, .partsSingle__Inner h6 {
      font-size: 18px;
      line-height: 1.6;
      color: #FFF; }
    .partsSingle__Inner .wp-caption {
      width: auto !important; }
      .partsSingle__Inner .wp-caption p {
        font-size: 12px;
        font-weight: lighter;
        color: #FFF; }
    .partsSingle__Inner p {
      font-size: 18px;
      line-height: 1.6;
      color: #FFF; }
    .partsSingle__Inner li {
      font-size: 18px;
      line-height: 1.6;
      color: #FFF; }
    .partsSingle__Inner span {
      font-size: 18px;
      line-height: 1.6;
      color: #FFF; }
    .partsSingle__Inner a {
      font-size: 18px;
      line-height: 1.6;
      color: #FFF;
      text-decoration: none; }
    .partsSingle__Inner mark {
      background: #e7f5fe; }
    .partsSingle__Inner blockquote {
      background: #e7f5fe;
      font-weight: lighter;
      font-size: 16px;
      padding: 25px;
      color: #004e99;
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      line-height: 1.8;
      margin-bottom: 60px; }
      .partsSingle__Inner blockquote p {
        margin: 0;
        line-height: 1.8;
        color: #004e99; }
    .partsSingle__Inner--intro {
      margin-top: 60px;
      margin-bottom: 60px; }
      .partsSingle__Inner--intro p {
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
      .partsSingle__Inner--intro a {
        color: #ff0000;
        border-bottom: 1px dotted #ff0000;
        text-decoration: none;
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
        .partsSingle__Inner--intro a:hover {
          border-bottom: 1px solid #ff0000; }
    .partsSingle__Inner input {
      width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #FFF;
      font-weight: lighter;
      line-height: 46px;
      font-size: 14px; }
    .partsSingle__Inner textarea {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #FFF;
      font-weight: lighter;
      font-size: 14px; }
    .partsSingle__Inner input[type="submit"] {
      border: 1px #dedede solid;
      display: inline-block;
      padding: 0 30px;
      line-height: 46px;
      text-decoration: none;
      font-size: 14px;
      color: #dedede;
      position: relative;
      overflow: hidden;
      z-index: 1;
      vertical-align: bottom;
      background: #dedede;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
      letter-spacing: 1px;
      font-weight: bold;
      color: #FFF; }
      .partsSingle__Inner input[type="submit"]:hover {
        color: #ff0000; }
        .partsSingle__Inner input[type="submit"]:hover:before {
          opacity: 1;
          right: -100px;
          left: -100px; }
      .partsSingle__Inner input[type="submit"]:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        top: -10px;
        bottom: -10px;
        transform: skew(40deg);
        background: #FFF;
        left: 100%;
        right: 110%;
        transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
      .partsSingle__Inner input[type="submit"]:hover {
        background: #ff0000;
        color: #FFF; }

.topBox {
  float: left;
  width: 100%; }
  .topBox__Description {
    padding: 60px 0;
    float: left;
    width: 100%;
    background: #ff0000; }
    .topBox__Description--title {
      background: #35394b;
      float: left;
      margin-top: -60px;
      padding: 10px 300px 10px 20px; }
      .topBox__Description--title h3 {
        color: #FFF;
        margin: 0; }
    .topBox__Description--image {
      float: left;
      width: 30%;
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin-top: 40px; }
    .topBox__Description--content {
      float: left;
      width: 65%;
      margin-left: 5%;
      margin-top: 40px; }
      .topBox__Description--content p {
        margin-top: 0;
        color: #FFF; }
      .topBox__Description--content--more {
        background: #35394b;
        color: #ff0000;
        text-transform: uppercase;
        padding-left: 40px;
        padding-right: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
        border: 0;
        display: inline-block;
        text-decoration: none;
        transition: 300ms;
        float: right;
        margin-top: 60px; }
        .topBox__Description--content--more:hover {
          color: #FFF;
          background: #ff0000; }
  .topBox__Locations {
    background: #00125f;
    float: left;
    width: 100%;
    padding: 30px 0; }
    .topBox__Locations ul {
      padding: 0;
      margin: 0;
      column-count: 4;
      text-align: left;
      max-width: 920px;
      margin: auto;
      list-style: none; }
    .topBox__Locations li {
      padding-left: 1em;
      text-indent: -.7em;
      margin-bottom: 5px;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid; }
      .topBox__Locations li:before {
        content: "• ";
        color: #35394b;
        font-size: 18px; }
    .topBox__Locations a {
      text-decoration: none;
      color: #ff0000;
      border-bottom: 1px solid transparent;
      font-size: 13px; }
      .topBox__Locations a:hover {
        border-color: #35394b; }
  .topBox__locationMap {
    height: 650px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    width: 100%;
    background-color: #00125f; }

.middleBox {
  float: left;
  width: 100%; }
  .middleBox__Description {
    padding: 60px 0;
    float: left;
    width: 100%;
    background: #ff0000; }
    .middleBox__Description--title {
      background: #ff0000;
      float: left;
      margin-top: -60px;
      padding: 10px 300px 10px 20px; }
      .middleBox__Description--title h3 {
        color: #FFF;
        margin: 0; }
    .middleBox__Description--image {
      float: left;
      width: 30%;
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin-top: 40px; }
    .middleBox__Description--content {
      float: left;
      width: 65%;
      margin-left: 5%;
      margin-top: 40px; }
      .middleBox__Description--content p {
        margin-top: 0;
        color: #FFF; }
      .middleBox__Description--content--more {
        background: #35394b;
        color: #ff0000;
        text-transform: uppercase;
        padding-left: 40px;
        padding-right: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
        border: 0;
        display: inline-block;
        text-decoration: none;
        transition: 300ms;
        float: right;
        margin-top: 60px; }
        .middleBox__Description--content--more:hover {
          color: #FFF;
          background: #ff0000; }
  .middleBox__Locations {
    background: #FFF;
    float: left;
    width: 100%;
    padding: 30px 0; }
    .middleBox__Locations .center {
      max-width: 860px; }
    .middleBox__Locations ul {
      padding: 0;
      margin: 0;
      text-align: left;
      float: left;
      width: 30%;
      margin-left: 1.5%;
      margin-right: 1.5%;
      list-style: none; }
      .middleBox__Locations ul h3 {
        text-transform: uppercase;
        font-weight: lighter;
        font-size: 28px;
        margin-bottom: 0; }
    .middleBox__Locations li {
      padding-left: 1em;
      text-indent: -.7em;
      margin-bottom: 5px; }
      .middleBox__Locations li:before {
        content: "• ";
        color: #35394b;
        font-size: 18px; }
    .middleBox__Locations a {
      text-decoration: none;
      color: #ff0000;
      border-bottom: 1px solid transparent; }
      .middleBox__Locations a:hover {
        border-color: #35394b; }
    .middleBox__Locations--image {
      float: left;
      width: 100%;
      height: 180px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin-top: 50px; }
  .middleBox__locationMap {
    height: 650px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    width: 100%;
    background-color: #00125f; }

.bottomBox {
  float: left;
  width: 100%; }
  .bottomBox__Description {
    padding: 60px 0;
    float: left;
    width: 100%;
    background: #00125f; }
    .bottomBox__Description--title {
      background: #e2e2e2;
      float: left;
      margin-top: -60px;
      padding: 10px 300px 10px 20px; }
      .bottomBox__Description--title h3 {
        color: #000;
        margin: 0; }
    .bottomBox__Description--image {
      float: left;
      width: 30%;
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin-top: 40px; }
    .bottomBox__Description--content {
      float: left;
      width: 65%;
      margin-left: 5%;
      margin-top: 40px; }
      .bottomBox__Description--content p {
        margin-top: 0;
        color: #000; }
      .bottomBox__Description--content--more {
        background: #35394b;
        color: #ff0000;
        text-transform: uppercase;
        padding-left: 40px;
        padding-right: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
        border: 0;
        display: inline-block;
        text-decoration: none;
        transition: 300ms;
        float: right;
        margin-top: 60px; }
        .bottomBox__Description--content--more:hover {
          color: #FFF;
          background: #ff0000; }
  .bottomBox__Locations {
    background: #00125f;
    float: left;
    width: 100%;
    padding: 30px 0; }
    .bottomBox__Locations ul {
      padding: 0;
      margin: 0;
      column-count: 4;
      text-align: center;
      list-style: none; }
    .bottomBox__Locations li {
      padding-left: 1em;
      text-indent: -.7em;
      margin-bottom: 5px; }
      .bottomBox__Locations li:before {
        content: "• ";
        color: #35394b;
        font-size: 18px; }
    .bottomBox__Locations a {
      text-decoration: none;
      color: #ff0000;
      border-bottom: 1px solid transparent; }
      .bottomBox__Locations a:hover {
        border-color: #35394b; }
  .bottomBox__locationMap {
    height: 650px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    width: 100%;
    background-color: #00125f; }

.footerBox {
  float: left;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 1; }
  .footerBox:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: 0.3;
    z-index: -1; }
  .footerBox__Box {
    float: left;
    width: 100%; }
    .footerBox__Box .center {
      border-top: 1px solid #FFF;
      padding: 60px 0;
      max-width: 860px; }
    .footerBox__Box:first-of-type .center {
      border-top: 0; }
    .footerBox__Box h2 {
      color: #FFF; }
    .footerBox__Box p {
      color: #FFF; }
    .footerBox__Box--more {
      background: #35394b;
      color: #ff0000;
      text-transform: uppercase;
      padding-left: 40px;
      padding-right: 40px;
      height: 40px;
      line-height: 40px;
      font-weight: normal;
      border: 0;
      display: inline-block;
      text-decoration: none;
      transition: 300ms;
      float: right;
      margin-top: 60px; }
      .footerBox__Box--more:hover {
        color: #FFF;
        background: #ff0000; }
  .footerBox .addedbrand {
    background: #ff0000; }
    .footerBox .addedbrand .center {
      border-top: 0; }

.justifyMe {
  text-align: justify !important; }

.googleMapContacts {
  float: left;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height: 400px;
  margin-top: 5%;
  margin-bottom: 5%; }
  .googleMapContacts .acf-map {
    float: left;
    width: 100%;
    height: 400px; }

.addMarginLeft {
  margin-left: 5px; }

#lightboxOverlay {
  z-index: 100000000; }

#lightbox {
  z-index: 100000000; }

.pageSingle {
  float: left;
  width: 100%;
  background: #FFF; }
  .pageSingle__Content {
    overflow: hidden;
    position: relative;
    padding-bottom: 100px; }
  .pageSingle__Top {
    width: 100%;
    max-width: 800px;
    margin: auto;
    text-align: center;
    margin-top: 70px; }
    .pageSingle__Top h2 {
      margin: 0;
      font-size: 48px;
      color: #ff0000;
      font-weight: lighter;
      line-height: 1.2;
      max-width: 800px;
      margin: auto;
      margin-bottom: 120px;
      position: relative;
      z-index: 1; }
      .pageSingle__Top h2:before {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 0;
        right: 0;
        background: #f0f0f0;
        height: 1px; }
    .pageSingle__Top h5 {
      color: #000;
      font-weight: bold; }
    .pageSingle__Top a {
      text-decoration: none;
      font-weight: normal;
      color: #000; }
  .pageSingle__Cover {
    float: left;
    width: 100%;
    height: 450px;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    margin-bottom: 50px; }
  .pageSingle__Map {
    float: left;
    width: 100%;
    height: 400px;
    margin-bottom: 50px;
    z-index: 10000000000000000; }
    .pageSingle__Map img {
      max-width: inherit !important; }
  .pageSingle__Inner {
    max-width: 665px;
    margin: auto; }
    .pageSingle__Inner h1 {
      font-size: 48px;
      line-height: 1.2;
      font-weight: lighter; }
    .pageSingle__Inner h2 {
      font-size: 26px;
      line-height: 1.4;
      font-weight: lighter; }
    .pageSingle__Inner h3 {
      font-size: 22px;
      font-weight: bold; }
    .pageSingle__Inner h4, .pageSingle__Inner h5, .pageSingle__Inner h6 {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .pageSingle__Inner .wp-caption {
      width: auto !important; }
      .pageSingle__Inner .wp-caption p {
        font-size: 12px;
        font-weight: lighter;
        color: #ff0000; }
    .pageSingle__Inner p {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .pageSingle__Inner li {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .pageSingle__Inner span {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .pageSingle__Inner a {
      font-size: 18px;
      line-height: 1.6;
      color: #ff0000;
      text-decoration: none;
      border-bottom: 1px dashed #ff0000; }
      .pageSingle__Inner a:hover {
        border-bottom: 1px solid #ff0000; }
    .pageSingle__Inner mark {
      background: #e7f5fe; }
    .pageSingle__Inner blockquote {
      background: #e7f5fe;
      font-weight: lighter;
      font-size: 16px;
      padding: 25px;
      color: #004e99;
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      line-height: 1.8;
      margin-bottom: 60px; }
      .pageSingle__Inner blockquote p {
        margin: 0;
        line-height: 1.8;
        color: #004e99; }
    .pageSingle__Inner--intro {
      margin-top: 60px;
      margin-bottom: 60px; }
      .pageSingle__Inner--intro p {
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
      .pageSingle__Inner--intro a {
        color: #ff0000;
        border-bottom: 1px dotted #ff0000;
        text-decoration: none;
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
        .pageSingle__Inner--intro a:hover {
          border-bottom: 1px solid #ff0000; }
    .pageSingle__Inner input {
      width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #000;
      font-weight: lighter;
      line-height: 46px;
      font-size: 14px; }
    .pageSingle__Inner textarea {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      border: 1px solid #dedede;
      padding: 10px;
      color: #000;
      font-weight: lighter;
      font-size: 14px; }
    .pageSingle__Inner input[type="submit"] {
      border: 1px #ff0000 solid;
      display: inline-block;
      padding: 0 30px;
      line-height: 46px;
      text-decoration: none;
      font-size: 14px;
      color: #ff0000;
      position: relative;
      overflow: hidden;
      z-index: 1;
      vertical-align: bottom;
      background: #ff0000;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
      letter-spacing: 1px;
      font-weight: bold;
      color: #FFF;
      font-weight: 300; }
      .pageSingle__Inner input[type="submit"]:hover {
        color: #ff0000; }
        .pageSingle__Inner input[type="submit"]:hover:before {
          opacity: 1;
          right: -100px;
          left: -100px; }
      .pageSingle__Inner input[type="submit"]:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        top: -10px;
        bottom: -10px;
        transform: skew(40deg);
        background: #FFF;
        left: 100%;
        right: 110%;
        transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
      .pageSingle__Inner input[type="submit"]:hover {
        border-color: #ff0000;
        color: #ff0000;
        background: #FFF; }

.pageSingle__Inner--box {
  float: left;
  width: 46%;
  position: relative;
  z-index: 1;
  margin-left: 2%;
  margin-right: 2%; }
  .pageSingle__Inner--box:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0px;
    background: #e2e2e2;
    height: 1px;
    z-index: -1; }
  .pageSingle__Inner--box p {
    font-size: 14px; }
  .pageSingle__Inner--box:last-of-type:before {
    display: none !important; }
  .pageSingle__Inner--box:nth-last-of-type(2):before {
    display: none !important; }

.full-width {
  width: 96% !important; }
  .full-width:before {
    display: none !important; }
  .full-width:first-of-type:before {
    display: block !important; }

.fullWidth {
  width: 100%; }

.page-template-page-club .limiterTitle {
  border-top: 1px solid #35394b;
  padding-top: 20px; }

.page-template-page-club .postSingle__Inner input {
  margin-bottom: 30px;
  font-size: 16px; }

.page-template-page-club .postSingle__Inner ul {
  padding: 0;
  margin: 0; }

.page-template-page-club .postSingle__Inner li {
  list-style: none; }

.page-template-page-club .postSingle__Inner span {
  font-size: 16px;
  color: #35394b; }

#wpsl-gmap img[src*="red@2x"] {
  display: none !important; }

#wpsl-gmap {
  height: 400px !important;
  width: 90% !important;
  margin-left: 5%;
  margin-right: 5%; }

.wpsl-directions {
  display: none !important; }

.wpsl-search {
  padding: 15px !important;
  margin: 40px auto !important;
  max-width: 700px; }

.wpsl-input {
  width: 70%;
  margin: 0 !important;
  padding-right: 2%; }
  .wpsl-input label {
    height: 40px !important;
    line-height: 40px;
    border-radius: 0 !important; }
  .wpsl-input input {
    height: 40px !important;
    line-height: 40px;
    border-radius: 0 !important; }
  .wpsl-input #wpsl-search-input {
    width: 75% !important; }

#wpsl-search-btn {
  background: #7c7c7c !important;
  color: #fff !important;
  border: 1px solid #7c7c7c !important;
  font-weight: lighter !important; }
  #wpsl-search-btn:hover {
    background: #f4f3f3 !important;
    color: #000 !important; }

.wpsl-search-btn-wrap {
  width: 30%;
  margin: 0 !important; }
  .wpsl-search-btn-wrap input {
    height: 40px !important;
    line-height: 40px;
    border-radius: 0 !important;
    width: 100%;
    border-radius: 0;
    border-style: 1px solid #000;
    background: #e2e2e2; }

#wpsl-result-list {
  margin: 40px auto !important;
  max-width: 700px; }
  #wpsl-result-list h1 {
    font-size: 18px;
    line-height: 1.2;
    font-weight: lighter; }
  #wpsl-result-list h2 {
    font-size: 18px;
    line-height: 1.4;
    font-weight: lighter; }
  #wpsl-result-list h3 {
    font-size: 18px;
    font-weight: bold; }
  #wpsl-result-list h4, #wpsl-result-list h5, #wpsl-result-list h6 {
    font-size: 18px;
    line-height: 1.6;
    color: #000; }
  #wpsl-result-list .wp-caption {
    width: auto !important; }
    #wpsl-result-list .wp-caption p {
      font-size: 12px;
      font-weight: lighter;
      color: #ff0000; }
  #wpsl-result-list p {
    font-size: 16px;
    line-height: 1.6;
    color: #000; }
  #wpsl-result-list li {
    font-size: 16px;
    line-height: 1.6;
    color: #000; }
  #wpsl-result-list span {
    font-size: 16px;
    line-height: 1.6;
    color: #000; }
  #wpsl-result-list a {
    font-size: 16px;
    line-height: 1.6;
    color: #ff0000;
    text-decoration: none;
    border-bottom: 1px dashed #ff0000; }
    #wpsl-result-list a:hover {
      border-bottom: 1px solid #ff0000; }
  #wpsl-result-list mark {
    background: #e7f5fe; }
  #wpsl-result-list blockquote {
    background: #e7f5fe;
    font-weight: lighter;
    font-size: 16px;
    padding: 25px;
    color: #004e99;
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    line-height: 1.8;
    margin-bottom: 60px; }
    #wpsl-result-list blockquote p {
      margin: 0;
      line-height: 1.8;
      color: #004e99; }

.wpsl-store-hours {
  position: relative; }

.wpsl-opening-hours tr {
  position: relative; }

.worktime-1 {
  font-size: 12px; }
  .worktime-1 span:before {
    position: absolute;
    left: 0;
    color: #000;
    font-size: 12px; }
  .worktime-1 span.dybr-hr:before {
    content: 'Pauza:'; }
  .worktime-1 span.dybr-en:before {
    content: 'Break:'; }

.singleRepresentation {
  float: left;
  width: 100%;
  min-height: 400px;
  padding-bottom: 50px;
  overflow: hidden;
  padding: 50px 0; }

.singleRepresentation h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #000000; }

.singleRepresentation h4 {
  color: rgba(102, 102, 102, 0.4);
  font-size: 18px;
  font-weight: 400;
  width: 55%;
  margin: auto;
  text-align: center;
  margin-bottom: 50px; }

.singleRepresentation__Box {
  width: 19%;
  float: left;
  height: 200px;
  margin-bottom: 1%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  background: #FFF;
  border: 1px solid rgba(226, 226, 226, 0.5); }

.singleRepresentation__Box a {
  float: left;
  width: 100%;
  background: #ffffff;
  text-align: center;
  background-size: 80% auto !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  height: 200px; }

.singleRepresentation__Hover {
  position: fixed;
  display: none;
  z-index: 100000000000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0; }

.singleRepresentation__Hover--inner {
  width: 600px;
  position: relative;
  margin: auto;
  top: 150px;
  background: #ffffff;
  padding: 20px; }

.singleRepresentation__Hover--inner .closeIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer; }

.singleRepresentation__Hover--inner p {
  color: #666666;
  font-size: 14px; }

.singleRepresentation__Hover--inner a {
  height: auto;
  font-size: 14px;
  text-align: left;
  float: none;
  text-decoration: none;
  color: #d32731;
  z-index: 1000000000000;
  position: relative; }

.singleRepresentation__Hover--inner a img {
  max-height: 40px; }

.singleRepresentation .mask {
  position: fixed;
  display: none;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000000000; }

.defineHeight {
  min-height: 530px; }

.activateLight {
  display: block !important; }

.jaw_months {
  text-transform: capitalize !important;
  margin-left: 10px !important;
  font-size: 14px !important; }

.siteHeader {
  position: fixed;
  float: left;
  width: 100%;
  z-index: 100000;
  height: 90px;
  background: transparent; }
  .siteHeader__Logo {
    float: left;
    width: 12%;
    position: relative;
    z-index: 100;
    text-align: left;
    line-height: 90px; }
    .siteHeader__Logo img {
      vertical-align: middle;
      max-height: 60px; }
  .siteHeader:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    right: 100%;
    background: #ff0000;
    z-index: -1;
    transition: 500ms; }
  .siteHeader .logoWhite {
    position: absolute;
    left: 0;
    top: 15px;
    opacity: 1;
    transition: 300ms; }
  .siteHeader .logoBlack {
    position: absolute;
    left: 0;
    top: 15px;
    opacity: 0;
    transition: 300ms; }

.js-SiteHeader {
  transition: background .7s ease-in-out; }
  .js-SiteHeader--is-Fixed {
    position: fixed;
    left: 0px;
    width: 100%;
    top: 0px;
    background: #FFF;
    height: 90px; }
    .js-SiteHeader--is-Fixed:before {
      right: 0;
      transition: 700ms; }
    .js-SiteHeader--is-Fixed .nav a {
      color: #000; }
    .js-SiteHeader--is-Fixed .nav ul li ul li {
      background: #FFF; }
    .js-SiteHeader--is-Fixed .logoWhite {
      opacity: 0; }
    .js-SiteHeader--is-Fixed .logoBlack {
      opacity: 1; }

.fbx-count {
  display: none; }

.rpwe-title {
  font-weight: 700 !important; }

.siteFooter {
  float: left;
  width: 100%;
  background: #222;
  padding: 80px 0;
  border-top: 10px solid #FFF;
  padding-bottom: 0; }
  .siteFooter__Widget {
    width: 30%; }
    .siteFooter__Widget:nth-of-type(1) {
      float: left; }
    .siteFooter__Widget:nth-of-type(3) {
      float: right; }
    .siteFooter__Widget:nth-of-type(2) {
      margin-left: 5%;
      float: left;
      margin-right: 5%; }
    .siteFooter__Widget h2 {
      font-size: 32px;
      color: #FFF; }
    .siteFooter__Widget p {
      font-size: 16px;
      color: #FFF;
      font-weight: lighter; }
    .siteFooter__Widget a {
      font-size: 16px;
      color: #FFF;
      text-decoration: none;
      font-weight: lighter; }
    .siteFooter__Widget i {
      margin-right: 10px;
      width: 20px; }
    .siteFooter__Widget img {
      max-width: 150px; }
    .siteFooter__Widget li {
      list-style: none;
      display: block; }
    .siteFooter__Widget ul {
      padding: 0;
      margin: 0; }
  .siteFooter__Copy {
    float: left;
    background: #333;
    margin-top: 80px;
    width: 100%;
    padding: 20px 0;
    color: #FFF; }
    .siteFooter__Copy span {
      float: left; }
    .siteFooter__Copy ul {
      list-style: none;
      float: right;
      padding: 0;
      margin: 0; }
      .siteFooter__Copy ul li {
        display: inline-block; }
        .siteFooter__Copy ul li a {
          color: #FFF;
          height: 30px;
          width: 30px;
          border: 1px solid #FFF;
          text-decoration: none;
          display: inline-block;
          text-align: center;
          line-height: 27px; }

.nav {
  position: relative;
  font-size: 16px;
  float: left;
  text-align: center;
  width: 88%;
  left: 0;
  z-index: 10;
  font-family: "Montserrat", sans-serif; }
  .nav ul {
    padding: 0;
    margin: 0 auto;
    list-style-type: none;
    text-align: left;
    display: block;
    height: 90px;
    line-height: 90px;
    float: right; }
  .nav li {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    position: relative; }
    .nav li ul {
      position: absolute;
      top: 74px;
      line-height: 1 !important;
      min-width: 200px;
      height: 100%;
      display: block;
      float: left;
      left: 15px;
      visibility: hidden;
      opacity: 0;
      transform: translateY(-100%);
      transition: 300ms;
      z-index: 1; }
      .nav li ul li {
        display: block;
        text-align: left;
        background: #000;
        padding-left: 10px;
        position: relative;
        z-index: 1; }
        .nav li ul li:first-of-type {
          padding-top: 10px; }
        .nav li ul li:last-of-type {
          padding-bottom: 10px; }
          .nav li ul li:last-of-type:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0px;
            height: 2px;
            right: 0;
            background: #ff0000;
            z-index: -1;
            transition: 500ms; }
        .nav li ul li a {
          line-height: 2.5 !important;
          padding: 0 !important;
          font-size: 13px; }
        .nav li ul li ul {
          position: absolute;
          top: 0;
          left: 300px;
          visibility: hidden;
          opacity: 0; }
          .nav li ul li ul li {
            background: rgba(0, 0, 0, 0.8); }
            .nav li ul li ul li a {
              text-transform: initial; }
    .nav li:hover ul {
      transform: translateY(0);
      opacity: 1;
      visibility: visible; }
    .nav li ul li ul {
      visibility: hidden !important;
      opacity: 0 !important; }
    .nav li ul li:hover ul {
      transform: translateY(0);
      opacity: 1 !important;
      visibility: visible !important; }
  .nav a {
    color: #FFF;
    font-size: 14px;
    text-decoration: none;
    line-height: 70px;
    margin: 0;
    display: block;
    position: relative;
    padding: 0 15px;
    z-index: 1;
    text-transform: uppercase; }
    .nav a:hover {
      color: #ff0000; }
  .nav li.current_page_item a {
    color: #ff0000; }
  .nav li.current_page_item ul li a {
    color: #FFF !important; }
    .nav li.current_page_item ul li a:hover {
      color: #ff0000 !important; }
  .nav li.current_page_item ul li ul li a {
    color: #FFF !important; }
    .nav li.current_page_item ul li ul li a:hover {
      color: #ff0000 !important; }

.foogallery-default:after {
  content: '';
  display: block;
  clear: both; }

.foogallery-default a {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  max-width: 100%; }

.foogallery-default a img {
  border-radius: 0;
  display: block;
  -webkit-transition: none;
  transition: none;
  max-width: 100%;
  height: auto; }

/* Horizontal alignment */
.foogallery-default a img {
  vertical-align: top; }

.foogallery-default.alignment-left {
  text-align: left; }

.foogallery-default.alignment-center {
  text-align: center; }

.foogallery-default.alignment-right {
  text-align: right; }

/* Gutter widths */
.foogallery-default.spacing-width-5 a {
  margin-right: 5px;
  margin-bottom: 5px; }

.foogallery-default.spacing-width-10 a {
  margin-right: 10px;
  margin-bottom: 10px; }

.foogallery-default.spacing-width-15 a {
  margin-right: 15px;
  margin-bottom: 15px; }

.foogallery-default.spacing-width-20 a {
  margin-right: 20px;
  margin-bottom: 20px; }

.foogallery-default.spacing-width-25 a {
  margin-right: 25px;
  margin-bottom: 25px; }

/* Border styles */
.foogallery-default.border-style-rounded a {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px; }

.foogallery-default.border-style-square-white a,
.foogallery-default.border-style-square-white a:hover {
  border: 5px solid #FFF !important;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5); }

.foogallery-default.border-style-circle-white a,
.foogallery-default.border-style-circle-white a:hover {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border: 5px solid #FFF !important;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5); }

.foogallery-default.border-style-circle-black a,
.foogallery-default.border-style-circle-black a:hover {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border: 3px solid #000 !important;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5); }

.foogallery-default.border-style-square-black a,
.foogallery-default.border-style-square-black a:hover {
  border: 3px solid #000 !important;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5); }

.foogallery-default.border-style-inset a {
  position: relative;
  max-width: 100%;
  content: ""; }

.foogallery-default.border-style-inset a:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: inset 0 0 20px #000;
  -moz-box-shadow: inset 0 0 20px #000;
  -webkit-box-shadow: inset 0 0 20px #000; }

/* Hover Effects */
.foogallery-default.hover-effect-zoom a,
.foogallery-default.hover-effect-zoom2 a,
.foogallery-default.hover-effect-zoom3 a,
.foogallery-default.hover-effect-plus a,
.foogallery-default.hover-effect-circle-plus a,
.foogallery-default.hover-effect-eye a,
.foogallery-default.hover-effect-tint {
  position: relative; }

.foogallery-default.hover-effect-zoom a:before,
.foogallery-default.hover-effect-zoom2 a:before,
.foogallery-default.hover-effect-zoom3 a:before,
.foogallery-default.hover-effect-plus a:before,
.foogallery-default.hover-effect-circle-plus a:before,
.foogallery-default.hover-effect-eye a:before,
.foogallery-default.hover-effect-tint a:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: none no-repeat center center;
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  -ms-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear; }

.foogallery-default.hover-effect-zoom a:hover:before {
  background: rgba(0, 0, 0, 0.5) url("../../../../plugins/foogallery/extensions/default-templates/shared/img/zoom.png") no-repeat center center; }

.foogallery-default.hover-effect-zoom2 a:hover:before {
  background: rgba(0, 0, 0, 0.5) url("../../../../plugins/foogallery/extensions/default-templates/shared/img/zoom2.png") no-repeat center center; }

.foogallery-default.hover-effect-zoom3 a:hover:before {
  background: rgba(0, 0, 0, 0.5) url("../../../../plugins/foogallery/extensions/default-templates/shared/img/zoom3.png") no-repeat center center; }

.foogallery-default.hover-effect-plus a:hover:before {
  background: rgba(0, 0, 0, 0.5) url("../../../../plugins/foogallery/extensions/default-templates/shared/img/plus.png") no-repeat center center; }

.foogallery-default.hover-effect-circle-plus a:hover:before {
  background: rgba(0, 0, 0, 0.5) url("../../../../plugins/foogallery/extensions/default-templates/shared/img/circle-plus.png") no-repeat center center; }

.foogallery-default.hover-effect-eye a:hover:before {
  background: rgba(0, 0, 0, 0.5) url("../../../../plugins/foogallery/extensions/default-templates/shared/img/eye.png") no-repeat center center; }

/* @2x Images (Pixel Ratio of 1.25+) */
@media only screen and (-o-min-device-pixel-ratio: 5 / 4), only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 1.25dppx) {
  .foogallery-default.hover-effect-zoom a:hover:before {
    background-image: url("../../../../plugins/foogallery/extensions/default-templates/shared/img/zoom@2x.png");
    background-size: 32px 32px; }
  .foogallery-default.hover-effect-zoom2 a:hover:before {
    background-image: url("../../../../plugins/foogallery/extensions/default-templates/shared/img/zoom2@2x.png");
    background-size: 32px 32px; }
  .foogallery-default.hover-effect-zoom3 a:hover:before {
    background-image: url("../../../../plugins/foogallery/extensions/default-templates/shared/img/zoom3@2x.png");
    background-size: 32px 32px; }
  .foogallery-default.hover-effect-plus a:hover:before {
    background-image: url("../../../../plugins/foogallery/extensions/default-templates/shared/img/plus@2x.png");
    background-size: 32px 32px; }
  .foogallery-default.hover-effect-circle-plus a:hover:before {
    background-image: url("../../../../plugins/foogallery/extensions/default-templates/shared/img/circle-plus@2x.png");
    background-size: 32px 32px; }
  .foogallery-default.hover-effect-eye a:hover:before {
    background-image: url("../../../../plugins/foogallery/extensions/default-templates/shared/img/eye@2x.png");
    background-size: 32px 32px; } }

.foogallery-default.hover-effect-tint a:hover:before {
  background-image: none !important;
  background-color: rgba(0, 0, 0, 0.5); }

.foogallery-default.hover-effect-color a img {
  background: none !important;
  /* For Webkit browsers */
  -webkit-filter: grayscale(1);
  -webkit-filter: grayscale(100%);
  /* Standard */
  filter: grayscale(100%);
  /* For IE 6 - 9 */
  filter: gray;
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  -ms-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear; }

.foogallery-default.hover-effect-color a:hover img {
  -webkit-filter: none;
  filter: none;
  opacity: 1 !important; }

.foogallery-default.hover-effect-color a:hover:before {
  background: none !important; }

.foogallery-default.hover-effect-none a:before,
.foogallery-default.hover-effect-none a:before:hover {
  background: none !important; }

/* Caption Styles */
.foogallery-default.hover-effect-caption a {
  position: relative; }

.foogallery-default.hover-effect-caption a:before,
.foogallery-default.hover-effect-caption a:before:hover {
  background: none !important; }

.foogallery-default .foogallery-caption {
  display: none;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  z-index: 100;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  width: 100%;
  overflow: hidden; }

.foogallery-default.hover-effect-caption .foogallery-caption-title,
.foogallery-default.hover-effect-caption .foogallery-caption-desc {
  color: #fff;
  font-size: 0.8em;
  padding: 0.25em; }

.foogallery-default.hover-effect-caption .foogallery-caption-title {
  font-size: 1em; }

.foogallery-default.hover-effect-caption.hover-caption-simple .foogallery-caption {
  bottom: 0;
  display: block;
  left: 0;
  text-align: center;
  -moz-transform: translateY(100%);
  -o-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%); }

.foogallery-default.hover-effect-caption.hover-caption-simple a:hover .foogallery-caption {
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0); }

.foogallery-default.hover-effect-caption.hover-caption-simple-always .foogallery-caption {
  display: block;
  left: 0;
  bottom: 0;
  text-align: center; }

.foogallery-default.hover-effect-caption.hover-caption-full-drop .foogallery-caption {
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  -moz-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%); }

.foogallery-default.hover-effect-caption.hover-caption-full-drop a:hover .foogallery-caption {
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0); }

.foogallery-default.hover-effect-caption.hover-caption-full-drop .foogallery-caption-inner {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.foogallery-default.hover-effect-caption.hover-caption-full-fade .foogallery-caption {
  display: block;
  opacity: 0;
  left: 0;
  top: 0;
  bottom: 0;
  text-align: center; }

.foogallery-default.hover-effect-caption.hover-caption-full-fade .foogallery-caption-inner {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.foogallery-default.hover-effect-caption.hover-caption-full-fade a:hover .foogallery-caption {
  opacity: 1; }

.foogallery-default.hover-effect-caption.hover-caption-push .foogallery-caption {
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  -moz-transform: translateX(100%);
  -o-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

.foogallery-default.hover-effect-caption.hover-caption-push a:hover .foogallery-caption {
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0); }

.foogallery-default.hover-effect-caption.hover-caption-push img {
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }

.foogallery-default.hover-effect-caption.hover-caption-push a:hover img {
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

.foogallery-default.hover-effect-caption.hover-caption-push .foogallery-caption-inner {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

/* Loading Styles */
.foogallery-default-loading {
  min-height: 11px;
  background: #fff url(data:image/gif;base64,R0lGODlhEAALAPQAAP///wAAANra2tDQ0Orq6gYGBgAAAC4uLoKCgmBgYLq6uiIiIkpKSoqKimRkZL6+viYmJgQEBE5OTubm5tjY2PT09Dg4ONzc3PLy8ra2tqCgoMrKyu7u7gAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCwAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQJCwAAACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQJCwAAACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQJCwAAACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAkLAAAALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkECQsAAAAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAkLAAAALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkECQsAAAAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA) no-repeat center center; }

.foogallery-default-loading a,
.foogallery-default-loading img {
  display: none; }

/* CUSTOM FORM */
.formWrapper {
  margin: 40px 0; }

.productFormOutbox {
  float: left;
  width: 100%;
  position: relative;
  margin-top: -500px; }

.productForm {
  position: absolute;
  background: #FFF;
  width: 665px;
  z-index: -10000000000;
  opacity: 0;
  top: -350px;
  padding: 50px;
  padding-bottom: 0;
  transition: opacity 500ms; }
  .productForm label {
    font-size: 13px;
    font-weight: lighter; }
  .productForm input {
    margin-bottom: 10px !important;
    height: 30px; }
  .productForm input[type="submit"] {
    height: 50px;
    font-weight: lighter; }
    .productForm input[type="submit"]:hover {
      color: #FFF; }
  .productForm textarea {
    margin-bottom: 10px; }
  .productForm p {
    margin: 0 !important;
    line-height: 1 !important; }
  .productForm__Call {
    padding: 10px 20px;
    text-transform: initial;
    color: #ff0000 !important;
    vertical-align: middle;
    border: 1px solid #ff0000;
    border-top: 0;
    border-bottom: 0;
    position: relative;
    z-index: 1;
    outline: none;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-weight: lighter;
    cursor: pointer; }
    .productForm__Call:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #ff0000;
      z-index: -1;
      transition: 300ms; }
    .productForm__Call:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #ff0000;
      z-index: -1;
      transition: 300ms; }
    .productForm__Call:hover:before {
      right: 30%; }
    .productForm__Call:hover:after {
      left: 30%; }
  .productForm__Close {
    position: absolute;
    float: right;
    right: 50px;
    transition: 500ms;
    top: 30px; }
    .productForm__Close:hover {
      color: #ff0000;
      transform: rotate(180deg) scale(1.3);
      cursor: pointer; }

.activeForm {
  opacity: 1;
  z-index: 10000000000; }

.activeBody {
  position: relative;
  z-index: 1;
  float: left;
  width: 100%;
  transition: opacity 500ms; }
  .activeBody:before {
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #000;
    opacity: 0.5;
    z-index: 1000000000;
    position: absolute; }

#wpsl-search-wrap .wpsl-input label {
  font-size: 15px; }

.mobile {
  display: none !important; }

@media only screen and (device-width: 1180px) {
  /* For general iPad layouts */
  .clients ul li {
    width: 40%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%; }
    .clients ul li img {
      max-width: 100px; } }

@media screen and (max-width: 1280px) {
  .nav {
    float: right; }
  .homeSlider h2 {
    font-size: 34px; }
  .homeSlider h4 {
    font-size: 24px; }
  .homeSlider__Slide {
    background-size: cover;
    background-position: center center !important;
    background-attachment: initial !important; }
  .advice {
    background-attachment: initial; }
  .getInTouch {
    background-attachment: initial;
    background-size: cover; } }

@media screen and (max-width: 800px) {
  .mobile {
    display: inline-block !important; }
  .productForm__Call {
    float: left;
    text-align: center; }
  .site {
    padding-top: 0; }
  .siteHeader {
    height: auto;
    position: relative;
    margin-bottom: 0;
    top: 0 !important;
    transform: translateY(0) !important; }
    .siteHeader__Top {
      height: auto;
      line-height: 1; }
    .siteHeader__Left {
      display: none; }
    .siteHeader__Right {
      display: none; }
    .siteHeader__Logo {
      width: 100%;
      padding-top: 0px;
      padding-bottom: 0px;
      text-align: right;
      padding-right: 15px; }
      .siteHeader__Logo img {
        max-width: 130px; }
      .siteHeader__Logo .logoWhite {
        left: auto;
        right: 10px;
        top: 10px; }
      .siteHeader__Logo .logoBlack {
        left: auto;
        right: 10px;
        top: 10px; }
  .nav {
    position: absolute;
    background: #FFF;
    transform: translateX(-100%);
    z-index: 1000000000;
    transition: 500ms !important; }
    .nav ul {
      height: auto;
      line-height: 1;
      display: block;
      float: left;
      width: 100%; }
    .nav li {
      display: table;
      float: none;
      width: auto; }
      .nav li ul {
        display: block;
        opacity: 1;
        visibility: visible;
        transform: translateY(0%);
        position: relative;
        top: auto; }
        .nav li ul li {
          background-color: transparent; }
          .nav li ul li a {
            line-height: 2 !important; }
          .nav li ul li:first-of-type {
            padding-top: 0px; }
          .nav li ul li:last-of-type {
            padding-top: 0px; }
            .nav li ul li:last-of-type:before {
              display: none; }
    .nav a {
      float: none;
      width: auto;
      line-height: 2;
      color: #35394b;
      text-align: left; }
    .nav li.current_page_item a {
      color: #35394b !important; }
  .callNav {
    position: absolute;
    right: -55px;
    top: 10px;
    padding: 10px 15px;
    background: #FFF;
    cursor: pointer; }
    .callNav i {
      color: #35394b;
      transition: 700ms; }
  .activeNav {
    transform: translateX(0%);
    opacity: 1;
    transition: 500ms !important; }
    .activeNav .callNav {
      right: 10px;
      background: #35394b; }
      .activeNav .callNav i {
        color: #FFF;
        transform: rotate(180deg); }
        .activeNav .callNav i:before {
          content: "\f00d" !important; }
  .homeSlider h2 {
    font-size: 34px; }
  .homeSlider h4 {
    font-size: 24px; }
  .homeSlider__Slide {
    background-size: cover;
    background-position: center center !important;
    background-attachment: initial !important; }
  .topLeft {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center !important; }
  .topCenter {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center !important; }
  .topRight {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center !important; }
  .centerLeft {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center !important; }
  .centerCenter {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center; }
  .centerRight {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center !important; }
  .bottomLeft {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center !important; }
  .bottomCenter {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center !important; }
  .bottomRight {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center !important; }
  .homeImage {
    top: 0;
    height: 100vh; }
    .homeImage:before {
      display: none; }
    .homeImage:after {
      display: none; }
  .about__Left {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
  .about__More {
    height: 48px;
    line-height: 50px; }
  .about h2 {
    font-size: 34px; }
  .about h3 {
    font-size: 24px; }
  .about p {
    font-size: 16px; }
  .advice {
    background-attachment: initial !important; }
    .advice__More {
      height: 48px;
      line-height: 50px; }
    .advice__Inner {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; }
    .advice h2 {
      font-size: 34px;
      margin-bottom: 30px; }
  .news {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
    .news__Cnt {
      width: 100%;
      margin-left: 0%;
      margin-right: 0%; }
    .news__Box {
      width: 100%;
      margin-right: 0;
      margin-bottom: 25px; }
    .news__More {
      height: 48px;
      line-height: 50px;
      padding: 13px 20px; }
    .news h2 {
      font-size: 34px; }
    .news h3 {
      font-size: 24px; }
  .getInTouch {
    padding: 40px 0;
    background-attachment: initial;
    background-size: cover; }
    .getInTouch .center {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; }
    .getInTouch__Left {
      width: 100%;
      margin-left: 0%;
      margin-right: 0%; }
    .getInTouch__Right {
      width: 100%;
      margin-left: 0%;
      margin-right: 0%; }
  .clients ul li {
    width: 40%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%; }
    .clients ul li img {
      max-width: 100px; }
  .siteFooter__Widget {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%; }
  .siteFooter__Copy {
    text-align: center; }
    .siteFooter__Copy span {
      margin-bottom: 15px;
      float: left;
      width: 100%; }
    .siteFooter__Copy ul {
      float: left;
      margin: auto;
      width: 100%; }
  .pageSingle__Inner {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
    .pageSingle__Inner--box {
      width: 96%; }
  .blog {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
    .blog__Box {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 25px; }
  .awwards {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
    .awwards__Box {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 25px; }
  .sidebar {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    float: left; }
  .postSingle__Top {
    padding-left: 5%;
    padding-right: 5%; }
  .postSingle__Inner {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    position: relative; }
    .postSingle__Inner ol li {
      font-size: 14px; }
  .postSingle__Left {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
  .postSingle__Right {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
  .postSingle__InnerWithNo {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
  .wpsl-store-below {
    width: 90% !important;
    margin: 0 5% !important; }
  .relatedPosts__Inner {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
  .relatedProducts__Box {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%; }
    .relatedProducts__Box h2 {
      font-size: 14px; }
  .machineCategory__Box {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%; }
  .categoryProducts__Box {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%; }
  .productSingle__Inner {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
    .productSingle__Inner--gallery a {
      width: 48%; }
  .productSingle__Cover {
    background-size: 100% auto;
    margin-bottom: 0;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
  .blogPage__Top {
    padding-top: 100px; }
  .blogPage__Content {
    padding: 0;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
  .blogPage__Box {
    width: 100%; }
    .blogPage__Box--cover {
      width: 100%; }
  .singleRepresentation__Box {
    width: 48%;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 2%; }
  .page-template-page-club .postSingle__Inner li {
    margin-bottom: 10px; }
  .page-template-page-club .postSingle__Inner span {
    font-size: 13px;
    line-height: 1.3 !important;
    float: left;
    width: 100%;
    margin-bottom: 20px; }
  .productFormOutbox {
    margin-top: -700px; }
  .productForm {
    width: 100%;
    padding: 10px;
    top: 0px; }
    .productForm__Close {
      top: 10px;
      right: 10px; }
  .singleRepresentation__Hover {
    position: absolute; }
    .singleRepresentation__Hover--inner {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      top: 2.5%;
      position: fixed; }
      .singleRepresentation__Hover--inner p {
        font-size: 12px; }
      .singleRepresentation__Hover--inner a {
        font-size: 12px; } }
